<template>
  <!-- 新闻展示组件1 -->
  <div class="newsListBody">
    <EmptyTip :show="newsList.length == 0 && loading == false"></EmptyTip>
    <div
      class="news"
      v-for="(item, index) in newsList"
      :key="index"
      @click="showDetail(item)"
    >
      <div class="newsContent">
        <p
          :class="iconColor ? 'contentRed' : 'contentGreen'"
          :v-html="
            iconColor
              ? highRedLight(item.title, item.title_classify_tags)
              : item.title
          "
        >
          {{ item.title }} <span v-if="item.is_frontier">*</span>
        </p>
      </div>
      <div class="newsListTitle">
        <div class="titleLeft">
          <span class="titleData">{{ item.release_time }}</span>
        </div>
        <div class="titleRight">
          <p class="titleSource" v-if="!item.isVip">{{ item.source }}</p>
          <p class="vip" v-else>VIP</p>
        </div>
      </div>
    </div>
    <NewsDetailShow
      v-if="newsDetailVisible"
      :visible="newsDetailVisible"
      :newsDetailData="newsDetailData"
      @changeVisible="handleNewsDetailVisible"
    ></NewsDetailShow>
  </div>
</template>
<script>
import EmptyTip from "@/components/EmptyTip.vue";
import NewsDetailShow from "@/components/NewsDetailShow.vue"

export default {
  components: {
    EmptyTip,
    NewsDetailShow
  },
  props: {
    newsList: Array,
    iconColor: String,
    loading: Boolean
  },
  watch: {
    newsList: {
      handler(newVal, oldVal) {
        console.log("newsNewVal", newVal)
        console.log("newsOldVal", oldVal)
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.newsDetailVisible = false
        }
        
      },
      deep: true
    }
  },
  data() {
    return {
      newsDetailData: {}, // 新闻详情
      newsDetailVisible: false,
      newsData: [],
      "--color": "red",
    };
  },
  methods: {
    handleNewsDetailVisible(val){
      this.newsDetailVisible=val
    },
    showDetail(item) {
      console.log("item:", item);
    //   if (!item.isVip) {
        // this.$store.state.newsDetailData = item;
        // this.$router.push("/NewsDetail");
        this.newsDetailData = item
        this.newsDetailVisible = true
    //   }
    },
    highRedLight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(
          eval(`/${keyword}/g`),
          `<span style="color:red;font-size:${font_size};">${keyword}</span>`
        );
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
  },
};
</script>
<style lang="scss">
.newsListBody {
  color: rgb(16, 16, 16);
  position: relative;
  .news {
    margin-bottom: 10px;
    border-bottom: 1px solid #efefef;
    padding-bottom: 7px;
    .newsListTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 13px;
      color: rgba(108, 108, 108, 1);
      .titleLeft {
        flex: 0.7;
      }
      .titleRight {
        flex: 3;
        display: flex;
        .vip {
          color: #b80014;
        }
      }
    }
    .newsContent {
      font-size: 17px;
      .contentRed,
      .contentGreen {
        // margin-left: 0.2rem;
      }
      .contentRed:hover {
        // font-size: 110%;
        color: #d9001b;
      }
      .contentGreen:hover {
        // font-size: 110%;
        color: #02a8db;
      }
    }
    .newsContent:hover {
      // font-size: 110%;
      // color: #02A8DB;
      // color:var(--color);
    }
  }
}
</style>