<template>
  <!-- 风险分布组件 -->
  <div class="RiskDistributionBody">
    <EmptyTip :show="apiData.length == 0"></EmptyTip>
    <div class="riskBox">
      <div
        class="risk"
        v-for="(item, index) in reskDistributionValue"
        :key="index"
        @click="handleClickRisk(item)"
        :style="{
          height: item.count * 1.2 + 'px',
        }"
      >
        <span style="white-space: nowrap">{{ item.tags_name }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import EmptyTip from "@/components/EmptyTip.vue"

export default {
  components: {
    EmptyTip
  },
  props: {
    apiData: Array,
  },
  data() {
    return {
      activeNames: ["1"],
      controlIcon: true,
      reskDistributionValue: [],
    };
  },
  components: {},
  computed: {},
  methods: {
    handleClickRisk(item) {
      console.log("handleClickRisk", item)
      this.$emit("handleClickRisk", item.tags_name)
    },
    changeIcon() {
      if (this.controlIcon) {
        // console.log('1')
        this.$refs.InvestmentInformationIcon.style =
          "transform: rotate(90deg);transition: all 0.3s linear;";
      } else {
        // console.log('2')
        this.$refs.InvestmentInformationIcon.style =
          "transform: rotate(0deg); transition: all 0.3s linear;";
      }
      this.controlIcon = !this.controlIcon;
    },
  },
  watch: {
    apiData: {
      handler(newVal, oldVal) {
        this.reskDistributionValue = newVal.slice(0, 5);
        //计算风险分布圆形大小
        let maxValue = 0;
        let minValue = 0;
        this.reskDistributionValue.forEach((element) => {
          if (element.count > maxValue) {
            maxValue = element.count;
          }
          if (element.count < minValue) {
            minValue = element.count;
          }
        });
        let maxNum = maxValue / 50;
        this.reskDistributionValue.forEach((element) => {
          element.count = element.count / maxNum;
        });
      },
    },
  },
  //   mounted() {
  //     let params = {
  //       node_id: this.$store.state.IndustryData,
  //     };
  //     api.getRiskDistribution(params).then((res) => {
  //       console.log("getRiskDistribution...... res:", res);
  //       // console.log('getRiskDistribution...... res:',res.data.slice(0,5))
  //       this.reskDistributionValue = res.data.slice(0, 5);
  //       //计算风险分布圆形大小
  //       let maxValue = 0;
  //       let minValue = 0;
  //       this.reskDistributionValue.forEach((element) => {
  //         if (element.count > maxValue) {
  //           maxValue = element.count;
  //         }
  //         if (element.count < minValue) {
  //           minValue = element.count;
  //         }
  //       });
  //       let maxNum = maxValue / 50;
  //       this.reskDistributionValue.forEach((element) => {
  //         element.count = element.count / maxNum;
  //       });
  //     });
  //   },
};
</script>
<style lang="scss">
.RiskDistributionBody {
  position: relative;
  .van-cell {
    flex-direction: row-reverse;
  }
  .van-collapse-item__content {
    color: #555;
    padding-top: 0rem;
  }
  .iconImg {
    height: 0.15rem;
    margin-right: 0.1rem;
  }
  .contentImg {
    width: 100%;
    // height: 2.75rem;
  }
  .riskBox {
    display: flex;
    align-items: flex-end;
    // justify-content: space-evenly;
    .risk {
      cursor: pointer;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      flex: 1;
      min-width: 56px;
      min-height: 30px;
      // min-width: 0.5rem;
      // min-width: 1rem;
      // min-height: 0.5rem;
      // min-height: var(size);

      background-color: #ec808d;
      border-radius: 50px 50px 0 0;
      line-height: 50px;
      // line-height: var(size);;
      text-align: center;
      // font-size: 0.05rem;
    }
  }
}
</style>