<template>
  <div class="RiskWarning">
    <EmptyTip :show="apiData.length == 0"></EmptyTip>
    <van-steps direction="vertical" active-color="#999" inactive-color="#999">
      <van-step v-for="risk in apiData" :key="risk">
        <p>
          <span class="RiskWarning-release_time">{{ risk.release_time }}</span>
          <span class="RiskWarning-source">{{ risk.source }}</span>
        </p>
        <p class="RiskWarning-title" @click="showDetail(risk)">
          {{ risk.title }}
        </p>
        <template #active-icon>
          <i class="iconColor">•</i>
        </template>
        <template #inactive-icon>
          <i class="iconColor">•</i>
        </template>
      </van-step>
    </van-steps>
    <NewsDetailShow
      :visible="newsDetailVisible"
      :newsDetailData="newsDetailData"
    ></NewsDetailShow>
  </div>
</template>
<script>
import EmptyTip from "@/components/EmptyTip.vue";
import NewsDetailShow from "@/components/NewsDetailShow.vue";

export default {
  components: {
    EmptyTip,
    NewsDetailShow,
  },
  props: {
    apiData: Array,
  },
  watch: {
    apiData: {
      handler(newVal, oldVal) {
        console.log("newsNewVal", newVal);
        console.log("newsOldVal", oldVal);
        this.newsDetailVisible = false;
      },
      deep: true,
    },
  },
  data() {
    return {
      newsDetailData: "",
      newsDetailVisible: false,
    };
  },
  methods: {
    showDetail(item) {
      console.log("item:", item);
      this.newsDetailData = item;
      this.newsDetailVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.RiskWarning {
  position: relative;
  .iconColor {
    color: #de868f;
    font-size: 45px;
  }
  &-release_time,
  &-source {
    color: rgb(108, 108, 108);
    font-size: 13px;
  }
  &-source {
    margin-left: 20px;
  }
  &-title {
    margin-top: 5px;
    color: black;
    cursor: pointer;
  }
}
</style>
