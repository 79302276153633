<template>
  <el-dialog :visible.sync="visible"  width="80vw" append-to-body>
    <div class="NewsDetailShowBody">
      <div class="title">
        <span class="titleContent">{{ newsDetailData.title }}</span>
        <div class="info">
          <span class="infoTime">{{ newsDetailData.release_time }}</span>
          <span class="infoSource">{{ newsDetailData.source }}</span>
        </div>
      </div>
      <!-- <div class="info">
            <span :class="newsDetailData.polarity=='1'? 'titleLabelGreen': 'titleLabelRed'" v-if="newsDetailData.polarity != null">{{newsDetailData.polarity=='1'? '正面':'负面'}}</span>
        </div> -->
      <div class="infoLabel">
        <span
          class="companyLabel"
          v-for="(item, index) in company_label"
          :key="index"
          >{{ item }}</span
        >
        <span
          class="otherLabel"
          v-if="newsDetailData.title_classify_array != undefined"
          >{{ newsDetailData.title_classify_array }}</span
        >
        <span
          :class="
            newsDetailData.polarity == '1' ? 'titleLabelGreen' : 'titleLabelRed'
          "
          v-if="newsDetailData.polarity != null"
          >{{ newsDetailData.polarity == "1" ? "正面" : "负面" }}</span
        >
      </div>
      <div class="aboutCompany">
        <span class="aboutName" v-if="Object.keys(aboutLabel).length > 0"
          >相关企业：</span
        >
        <span
          class="aboutLabel"
          v-for="(item, index) in aboutLabel"
          :key="index"
          >{{ item }}</span
        >
      </div>
      <div class="hrStyle">
        <hr />
        <div class="hrText">文章内容</div>
      </div>

      <div
        class="newsContent"
        v-if="newsDetailData.html_text"
        v-html="newsDetailData.html_text"
      ></div>

      <div class="newsContent" v-else v-html="newsDetailData.content"></div>
      <div slot="footer" class="dialog-footer">
        <div @click="toOriginNews(newsDetailData.url)">
            <Icon type="md-paper-plane" />前往原网页
        </div>
      </div>  
      <!-- <div class="backToHome">
            <button @click="backToHome">返回</button>
        </div> -->
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    newsDetailData: Object,
    visible: Boolean
  },
  data() {
    return {
      company_label: [],
      aboutLabel: [],
      detailVisible:false
    };
  },
  watch:{
    visible:{
            immediate: true,
            handler(newValue){
                this.detailVisible = newValue
                this.$emit('changeVisible',this.detailVisible)
            }
        },
  },
  methods: {
    backToHome() {
      // console.log('back')
      this.$store.state.isNotNewsShow = true;
    },
    toOriginNews(url){
      console.log(this.infoData) 
      window.open(url, "_blank");
    },
  },
  mounted() {
    console.log('newsDetailShow create')
    window.scrollTo(0, 0);
    if (
      this.newsDetailData.company_list != undefined ||
      this.newsDetailData.company_list != null
    ) {
      // extract_type =1 为公司标签 || =2 为相关企业标签
      this.newsDetailData.company_list.forEach((element) => {
        if (element.extract_type == 1) {
          this.company_label.push(element.short_name);
        } else if (element.extract_type == 2) {
          this.aboutLabel.push(element.short_name);
        }
      });
    }

    //判断htmlText是否为空,并将a标签改为p
    if (this.newsDetailData.html_text != null) {
      this.newsDetailData.html_text = this.newsDetailData.html_text
        .replace(/<a/g, "<i")
        .replace(/a>/g, "i>");
    }
  },
};
</script>
<style lang="scss">
.NewsDetailShowBody {
  // margin-top: 0.5rem;
  // margin-left: 10px;
  padding: 0.2rem 15px 10px 15px;
  .title {
    .titleContent {
      margin-right: 30px;
      font-size: 18px;
      color: #333;
      font-weight: bold;
    }
  }
  .info {
    display: inline-block;
    margin-top: 10px;
    color: #999;
    .infoTime {
      margin-right: 30px;
    }
  }
  .titleLabelGreen {
    background-color: #f5fee6;
    color: #70b603;
    padding: 2px 8px;
    border-radius: 2px;
    margin-bottom: 4.5px;
  }
  .titleLabelRed {
    background-color: #fef0f0;
    color: #d9001b;
    padding: 2px 8px;
    border-radius: 2px;
    margin-bottom: 4.5px;
  }
  .infoLabel {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .companyLabel {
      margin-right: 5px;
      background-color: #67c23a;
      color: #fff;
      border-radius: 5px;
      padding: 2px 10px;
      margin-bottom: 5px;
    }
    .otherLabel {
      margin-right: 5px;
      border: 1px solid #53a7f2;
      color: #53a7f2;
      padding: 2px 1px;
      border-radius: 5px;
      margin-bottom: 5px;
    }
  }
  .aboutCompany {
    margin-top: 10px;
    .aboutName {
      font-size: 15px;
      font-weight: 900;
    }
    .aboutLabel {
      display: inline-block;
      font-size: 15px;
      padding: 2px;
      border-radius: 5px;
      border: 1px solid #b2e09b;
      margin-right: 10px;
      margin-bottom: 4px;
      color: #67c23a;
    }
  }
  .hrStyle {
    margin-top: 20px;
    hr {
      text-align: center;
      line-height: 0px;
      height: 1px;
      border: none;
      background-color: #dbd6d6;
    }
    .hrText {
      height: 20px;
      width: 50px;
      position: relative;
      margin: 0 auto;
      top: -10px;
      font-size: 12px;
      vertical-align: middle;
      text-align: center;
      color: #dbd6d6;
      background-color: #fff;
    }
  }
  .backToHome {
    width: 100%;
    button {
      margin-left: 90%;
    }
  }
  .newsContent {
    img {
      // width: 3rem;
      width: 60% !important;
      margin-left: 20%;
      height: auto !important;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    font-size: 20px;
    color: #169BD5;
    i{
        // margin-right: 15px;
        font-size: 35px !important;
        margin-right: 10px;
    }
    div:hover{
        cursor: pointer;
    }
  }
}
</style>
