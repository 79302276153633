<template>
  <div class="CategoryGraph">
    <div class="headerTop">
      <!-- <el-radio-group
        v-model="tabRadio"
        size="medium"
        @change="handleClickTab"
        style="margin: 15px 0 10px 15px"
      >
        <el-radio-button label="产业生态"></el-radio-button>
        <el-radio-button label="产业生态"></el-radio-button>
        <el-radio-button label="产业链"></el-radio-button>
      </el-radio-group> -->
      <div class="diyTab" v-if="tabRadio != '首页' && !is_custom">
        <div :class="tabRadio == item ? 'diyTab-itemSelected' : 'diyTab-item'" v-for="item in tabList" :key="item"
          @click="handleClickDiyTab(item)">
          {{ item }}
        </div>
      </div>
      <!-- <el-button
        v-if="
          tabRadio == '产业链' &&
          subscribeNodeName.indexOf(node_name_select) != -1
        "
        class="btn"
        size="small"
        type="primary"
        @click="cancelSubscribe"
        :loading="cancelLoading"
        >已订阅</el-button
      > -->
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '首页'" v-loading="graphOptionRadarLoading"
      style="justify-content: center">
      <div style="padding-top: 3vh; width: 100%">
        <!-- <p v-if="optionList.length === 0" style="margin: 30px 0 0 30px;font-weight: bold;">当前无任何权限 !</p> -->
        <el-input placeholder="请输入产业名称" v-model="searchQuery" class="input-with-select" style="
            width: 50%;
            margin-left: 25%;
            margin-top: 10px;
            margin-bottom: 10px;
            box-shadow: 5px 0 12px 0 silver;
          " size="medium" @keyup.enter.native="handleNodeSearch(searchQuery)">
          <el-button class="industry_search_icon" slot="append" icon="el-icon-search" type="primary" size="mini"
            @click="handleNodeSearch(searchQuery)"></el-button>
        </el-input>
        <div style="display: flex; position: relative; width: 100%">
          <img class="arrow-left" src="@/assets/images/arrowLeft.png" @click="handleClickArrow(0)"
            v-if="current_arrow_index != 0" />
          <div class="industryType" style="margin-bottom: 25px; width: 100%; display: flex"
            :style="`margin-left: -${80 * current_arrow_index}%`">
            <div class="industryBox" v-for="o in optionListRadarCut" :key="o.industry_type">
              <!-- X.X 修改首页搜素产业：光伏 报错卡死的问题 错误定位：v-for的key重复，报错无法渲染 修改内容：修改了v-for的key为i.name(原为i.image_url) -->
              <div class="industryBox-item" v-for="i in o.option_list" :key="i.name" @click="handleClickIndustryBox(i)">
                <vue-context-menu :contextMenuData="contextMenuData" @toGraph="toGraph()"></vue-context-menu>
                <img class="industryBox-item-img" :src="i.image_url" :style="setBoxStyle(i)" />
                <div class="lockBox" v-if="i.is_online == 0">
                  <div class="lockLink">
                    <img class="lockLink-img" src="@/assets/images/lock_link.png" />
                    <p>敬请期待...</p>
                  </div>
                </div>
                <div class="lockBox" v-if="i.is_online == 1 && i.clickable == false">
                  <img class="lockImg" src="@/assets/images/lock.png" />
                </div>
                <div class="industryBox-text" :style="i.is_online ? 'color: #333;' : 'color: rgb(190,190,190)'
                  ">
                  {{ i.name }}
                </div>
              </div>
            </div>
          </div>
          <img class="arrow-right" src="@/assets/images/arrowLeft.png" @click="handleClickArrow(1)"
            v-if="current_arrow_index < boxPage - 1" />
        </div>
      </div>
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '产业链'">
      <div style="width: 40%; overflow-x: hidden; overflow-y: scroll">
        <div class="btnsubscribeBox">
          <BtnSubscribe :is_show="!is_custom" :subscribeNodeName="subscribeNodeName" :node_name_select="node_name_select"
            :domain_id_select="domain_id_select"></BtnSubscribe>
          <span v-if="is_custom" class="btnsubscribeTooltip">右键点击节点进行产业链设置</span>
        </div>
        <GraphCategory ref="graphCategory" v-loading="treeLoading" :select_node="node_name_select" :is_custom="is_custom"
          :nodes="nodes" :node_link_list="node_link_list" :links="links" :eheight="800" :longEdge="true"
          style="width: 100%; height: 90%" @clickIndustryGraph="handleClickIndustryGraph"></GraphCategory>
        <div class="nodeDesc">{{ nodeDesc }}</div>
      </div>
      <div class="rightPart">
        <div class="circleTab">
          <!-- 自定义产业和其他产业tab栏区分控制 -->
          <template v-if="is_custom">
            <!-- <div
              :class="
                current_tab == item
                  ? 'circleTab-item circleTab-itemSelected'
                  : 'circleTab-item'
              "
              v-for="item in linkTabListCustom"
              :key="item"
              @click="handleClickLinkTab(item)"
            >
              {{ item }}
            </div> -->
          </template>
          <template v-else>
            <div :class="current_tab == item
              ? 'circleTab-item circleTab-itemSelected'
              : 'circleTab-item'
              " v-for="item in linkTabList" :key="item" @click="handleClickLinkTab(item)">
              {{ item }}
            </div>
          </template>
        </div>
        <div class="tabModule" v-if="current_tab == '产业玩家'" v-loading="companyListLoading">
          <div class="selectType">
            <div class="selectType-img" @click="changeGlobalOrChina(1, 2)" v-if="tab == 2">
              <!-- <div :class="tab == 1 ? 'high' : ''"></div> -->
              <img src="@/assets/images/china1.png" alt />
            </div>
            <div class="selectType-img" @click="changeGlobalOrChina(2, 2)" v-else>
              <!-- <div :class="tab == 2 ? 'high' : ''"></div> -->
              <img src="@/assets/images/world1.png" alt />
            </div>
          </div>
          <world-new v-if="tab == 2" :data="worldListMarket" eheight="30vh"
            style="width: 25vw; margin-left: 6.5vw"></world-new>
          <MapIndustry v-if="tab == 1" style="width: 25vw; margin-left: 6.5vw" eheight="30vh" :data="mapLatentMarket">
          </MapIndustry>
          <!-- <p
            style="
              font-weight: bold;
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
              margin-bottom: 10px;
            "
          >
            ▪ 公司列表
          </p> -->
          <!-- <p>test</p> -->
          <div class="companyTyleList" v-if="is_company != 1">
            <el-radio-group v-model="tabPosition" style="margin-top: 30px; margin-bottom: 10px" @change="chooseQY">
              <el-radio-button label="头部企业" size="small">头部企业</el-radio-button>
              <el-radio-button label="潜优企业" size="small">潜优企业</el-radio-button>
              <el-radio-button label="新兴企业" size="small">新兴企业</el-radio-button>
            </el-radio-group>
          </div>

          <div class="companyTypeTable">
            <el-table :data="downstreamCompanyList" stripe style="width: 100%; margin-bottom: 10px" :header-cell-style="{
              background: 'rgb(239, 239, 239)',
              color: 'rgb(79, 79, 79)',
            }" border height="400">
              <el-table-column prop="name" label="公司"> </el-table-column>
              <el-table-column prop="loc_province" label="地区">
              </el-table-column>
              <el-table-column prop="enterprises_type" label="企业类型">
              </el-table-column>
              <el-table-column prop="founded_time" sortable width="110" label="成立时间">
              </el-table-column>
              <el-table-column label="详情" width="70">
                <template slot-scope="scope">
                  <el-button @click="selectCompany(scope.row)" type="text">查看</el-button>
                </template>
              </el-table-column>
              <el-table-column label="订阅" width="70" align="center">
                <!-- fixed="right" -->
                <template slot-scope="scope">
                  <Star :apiData="scope.row" :type="'company'" :isSearch="false"></Star>
                </template>
              </el-table-column>
              <el-table-column label="名录标签" width="130">
                <template slot-scope="scope">
                  <span class="labelItem" v-for="(item, index) in scope.row.allLabel" :key="index">
                    {{ item }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <div class="showMoreBox">
              <el-pagination v-if="this.tabPosition == '头部企业'" @current-change="handleCompanyTypePageChange"
                :current-page="companyTypePage" :page-size="companyTypePageSize" layout="total, prev, pager, next"
                :total="companyTypeTotal" :hide-on-single-page="true">
              </el-pagination>
              <el-button type="primary" size="mini" class="tableMore" v-if="domain_range > -1"
                @click="showMoreCompany">搜索更多</el-button>
            </div>
          </div>
        </div>
        <div class="tabModule" v-if="current_tab == '产业前沿'">
          <div class="subtitleSmall"><span>■</span> 前沿机构与前沿技术方向</div>
          <div class="mechainismAndWord">
            <IndustryFrontierMechanism style="width: 25%" :dataList="mechainismList" :loading="mechainismLoading"
              @clickCompany="handleClickCompany" v-loading="mechainismLoading"></IndustryFrontierMechanism>
            <NewsKeywordCloud style="width: 75%; height: 420px" :apiDataLineChart="wordList"
              v-loading="frontierWordLoading"></NewsKeywordCloud>
          </div>
          <div class="subtitleSmall"><span>■</span> 前沿科技资讯</div>
          <div v-loading="frontierNewsLoading">
            <NewsListsShow ref="NewsListsShow" :newsList="newsList" :loading="frontierNewsLoading"
              style="margin-left: 20px; margin-top: 20px; max-height: 500px;overflow-y: scroll;">
            </NewsListsShow>
          </div>
        </div>
        <div class="tabModule" v-if="current_tab == '项目投资'">
          <div class="subtitleSmall">
            <span style="color: #6c6c6c; font-size: 16px; margin-right: 5px">■</span>
            项目投资概况
          </div>
          <p class="textSmall">
            项目投资概况反应该产业在全国项目开展与产业投资建设分布情况。
          </p>
          <OverviewMap :mapData="mapData" v-loading="regionLoading"></OverviewMap>
          <div class="subtitleSmall" style="margin-top: 20px">
            <span style="color: #6c6c6c; font-size: 16px; margin-right: 5px">■</span>
            项目投资资讯
          </div>
          <div v-loading="industryInvestmentDetailLoading">
            <NewsListsShow ref="investNest" :newsList="investmentNewsList"
              style="margin-left: 20px; margin-top: 20px; max-height: 500px;overflow-y: scroll;"
              :loading="industryInvestmentDetailLoading"></NewsListsShow>
          </div>
        </div>
        <div class="tabModule" v-if="current_tab == '产业风险'">
          <div class="subtitleSmall"><span>■</span> 风险分布</div>
          <RiskDistribution :apiData="riskDistributionData" class="riskDistribution" v-loading="riskDistributionLoading"
            @handleClickRisk="handleClickRisk" style="min-height: 100px"></RiskDistribution>
          <div class="subtitleSmall"><span>■</span> 风险预警</div>
          <div v-loading="riskWarningLoading">
            <RiskWarning ref="riskWarning" :apiData="riskWarningData" style="max-height: 500px;overflow-y: scroll;">
            </RiskWarning>
          </div>
        </div>
        <div class="tabModule" v-if="current_tab == '产业政策'">
          <div v-loading="industryPolicyLoading">
            <IndustryPolicy ref="industryPolicy" :apiData="industryPolicyData"
              style="max-height: 500px;overflow-y: scroll;">
            </IndustryPolicy>
          </div>
        </div>
      </div>
    </div>
    <div class="newsEchartsBox" v-if="tabRadio == '产业生态'" style="justify-content: center">
      <div class="market">
        <div class="market-top">
          <div style="
              height: auto;
              overflow-y: scroll;
              display: flex;
              justify-content: center;
              margin-top: 15px;
            " v-loading="positionLoading">
            <div class="industryLink" v-if="links_position.upUpStream.length != 0">
              <p style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                ">
                上上游
              </p>
              <img class="doubleArrow" src="@/assets/images/doubleArrow.png" />
              <div class="industryLink-item" v-for="u in links_position.upUpStream" :key="u.name">
                <div :class="u.name == industrySelected ||
                  industryImportant.indexOf(u.name) != -1
                  ? 'industryLink-headSelected'
                  : 'industryLink-head'
                  " @click="chooseIndustry(u)">
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink-subItem">
                  <div class="industryLink-subItem-noChild" v-for="c in u.children" :key="c.name">
                    <div class="industryLink-subItem-head" style="padding: 0 2px 0 2px; color: #58b6e5">
                      {{ c.name }}
                    </div>
                    <div class="industryLink-subItem">
                      <div class="industryLink-subItem-noChild" v-for="y in c.children" :key="y.name">
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="industryLink">
              <p style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                ">
                上游
              </p>
              <img class="doubleArrow" src="@/assets/images/doubleArrow.png" />
              <div class="industryLink-item" v-for="u in links_position.upStream" :key="u.name">
                <div :class="u.name == industrySelected ||
                  industryImportant.indexOf(u.name) != -1
                  ? 'industryLink-headSelected'
                  : 'industryLink-head'
                  " @click="chooseIndustry(u)">
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink-subItem">
                  <div class="industryLink-subItem-noChild" v-for="c in u.children" :key="c.name">
                    <div :class="c.name == industrySelected ||
                      industryImportant.indexOf(c.name) != -1
                      ? 'industryLink-subItem-headSelected'
                      : 'industryLink-subItem-head'
                      " :id="c.name" @click="chooseIndustry(c)">
                      {{ c.name }}
                    </div>
                    <div class="industryLink-subItem" v-if="c.children">
                      <div class="industryLink-subItem-noChild" v-for="y in c.children" :key="y.name">
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="industryLink industryLink_medium">
              <p style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                ">
                中游
              </p>
              <img class="doubleArrow" src="@/assets/images/doubleArrow.png" />
              <div class="industryLink-item" v-for="u in links_position.mediumStream" :key="u.name">
                <div :class="u.name == industrySelected
                  ? 'industryLink-headNow'
                  : industryImportant.indexOf(u.name) != -1
                    ? 'industryLink-headSelected'
                    : 'industryLink-head'
                  " @click="chooseIndustry(u)">
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink-subItem">
                  <div class="industryLink-subItem-noChild" v-for="c in u.children" :key="c.name">
                    <div :class="c.name == industrySelected ||
                      industryImportant.indexOf(c.name) != -1
                      ? 'industryLink-subItem-headSelected'
                      : 'industryLink-subItem-head'
                      " @click="chooseIndustry(c)" :id="c.name">
                      {{ c.name }}
                    </div>
                    <div class="industryLink-subItem" v-if="c.children">
                      <div class="industryLink-subItem-noChild" v-for="y in c.children" :key="y.name">
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="industryLink">
              <p style="
                  text-align: center;
                  margin-bottom: -10px;
                  font-weight: bold;
                  color: #58b6e5;
                ">
                下游
              </p>
              <img class="doubleArrow" src="@/assets/images/doubleArrow.png" />
              <div class="industryLink-item" v-for="u in links_position.downStream" :key="u.name">
                <div :class="u.name == industrySelected ||
                  industryImportant.indexOf(u.name) != -1
                  ? 'industryLink-headSelected'
                  : 'industryLink-head'
                  " @click="chooseIndustry(u)">
                  <span :id="u.name">{{ u.name }}</span>
                </div>
                <div class="industryLink-subItem">
                  <div class="industryLink-subItem-noChild" v-for="c in u.children" :key="c.name">
                    <div :class="c.name == industrySelected ||
                      industryImportant.indexOf(c.name) != -1
                      ? 'industryLink-subItem-headSelected'
                      : 'industryLink-subItem-head'
                      " @click="chooseIndustry(c)" :id="c.name">
                      {{ c.name }}
                    </div>
                    <div class="industryLink-subItem" v-if="c.children">
                      <div class="industryLink-subItem-noChild" v-for="y in c.children" :key="y.name">
                        {{ y.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span style="font-weight: bold; color: #337ab7">[ {{ industrySelected }} ]</span>
          <p class="industryDesc">
            {{ industryDesc }}
          </p>
        </div>
      </div>
    </div>
    <!-- 公司详细信息 -->
    <companyDetailHori ref="companyDetailHori"></companyDetailHori>
    <el-dialog title="产业成本" :visible.sync="showCost">
      <el-table :data="tableCost">
        <el-table-column label="新能源车热泵系统">
          <el-table-column prop="part" label="零部件"></el-table-column>
          <el-table-column prop="price" label="单车价格(元)"></el-table-column>
        </el-table-column>
        <el-table-column label="2020">
          <el-table-column prop="in20" label="国内"></el-table-column>
          <el-table-column prop="out20" label="国际"></el-table-column>
        </el-table-column>
        <el-table-column label="2025">
          <el-table-column prop="in25" label="国内"></el-table-column>
          <el-table-column prop="out25" label="国际"></el-table-column>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="评分明细" width="98%" top="9vh" :visible.sync="showScoreDetail" :destroy-on-close="false">
      <el-table :data="downstreamCompanyList" stripe style="
          width: 100%;
          margin-top: 10px;
          margin-bottom: 20px;
          overflow-x: auto !important;
        " border height="500">
        <el-table-column prop="full_name" label="公司名" width="220" fixed>
        </el-table-column>
        <el-table-column prop="short_name" label="简称" width="120">
        </el-table-column>
        <el-table-column prop="board" label="板块" width="100">
        </el-table-column>
        <el-table-column prop="registered_capital" label="注册资本" sortable width="150">
        </el-table-column>
        <el-table-column prop="staff_num" label="员工数量" sortable width="120">
        </el-table-column>
        <el-table-column prop="ratepayer_num" label="纳税人识别号" width="200">
        </el-table-column>
        <el-table-column prop="address" label="地址" width="200">
        </el-table-column>
        <el-table-column prop="last_income" label="上一年营收" sortable width="170">
        </el-table-column>
        <el-table-column prop="last_income_ratio" label="上一年营收增长率" sortable width="100">
        </el-table-column>
        <el-table-column prop="last_profit" label="上一年净利润" sortable width="170">
        </el-table-column>
        <el-table-column prop="last_profit_ratio" label="上一年净利润增长率" sortable width="100">
        </el-table-column>
        <el-table-column prop="last_coreproduct" label="上一年核心产品" width="150">
        </el-table-column>
        <el-table-column prop="last_coreproduct_income" label="上一年核心产品营收" sortable width="200">
        </el-table-column>
        <el-table-column prop="patent_sum" label="专利数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="last_patent_num" label="上一年专利数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="down_customer_num" label="下游公司数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="subsidiary_num" label="子公司数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="positive_num" label="正面舆情数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="half_positive_num" label="半年内正面舆情数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="negative_num" label="负面舆情数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="half_negative_num" label="半年内负面舆情数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="legal_num" label="法律文书数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="last_legal_num" label="一年内法律文书数量" sortable width="100">
        </el-table-column>
        <el-table-column prop="industry_category" label="行业种类" width="100">
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- <el-dialog title="评分项明细" :visible.sync="showScoreDetail">
      <el-form
        label-position="right"
        :model="formData"
        class="demo-form-inline"
      >
        <el-form-item label="公司全称">
          <el-input
            v-model="formData.full_name"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="是否上市">
          <el-input
            v-model="isOrNot"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上市板块" v-if="formData.is_listed == 1">
          <el-input
            v-model="formData.board"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上一年营收">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上一年营收增长率">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上一年净利润">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上一年净利润增长率">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上一年核心产品">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上一年核心产品营收">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="注册资本">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="参保人数">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="专利总数">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="上一年专利数量">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="下游客户企业数量">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="核心产品市场份额排名">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="负面舆情总数">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="一年内负面舆情数量">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="法律文书总数">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item label="一年内法律文书数量">
          <el-input
            v-model="formData.income"
            style="width: 50%"
            size="small"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
    </el-dialog> -->
    <el-dialog title="招聘信息" :visible.sync="showPost">
      <p class="postDetail-post">
        {{ postInfoDetail.name }}
        <span>{{ postInfoDetail.salary }}</span>
      </p>
      <p class="postDetail-company">{{ postInfoDetail.company_name }}</p>
      <p class="postDetail-info">
        <span> {{ postInfoDetail.region }} </span>
        <span class="postDetail-line">|</span>
        <span> {{ postInfoDetail.experience }} </span>
        <span class="postDetail-line">|</span>
        <span> {{ postInfoDetail.education }} </span>
        <span class="postDetail-line">|</span>
        <span> 招{{ postInfoDetail.num ? postInfoDetail.num : "1" }}人 </span>
        <span class="postDetail-line">|</span>
        <span>{{ postInfoDetail.release_date }} 发布</span>
        <span class="postDetail-line">|</span>
        <span>{{ postInfoDetail.source }}</span>
      </p>
      <div v-if="postInfoDetail.key_word">
        <el-tag type="primary" size="small" style="margin-right: 8px; margin-top: 10px"
          v-for="t in postInfoDetail.keyword_tags" :key="t">{{ t }}</el-tag>
      </div>
      <div v-if="postInfoDetail.other_label">
        <el-tag type="success" size="small" style="margin-right: 8px; margin-top: 10px" v-for="t in postInfoDetail.tags"
          :key="t">{{ t }}</el-tag>
      </div>
      <p style="margin-top: 10px" v-if="postInfoDetail.post_category">
        职能类别：{{ postInfoDetail.post_category }}
      </p>
      <p class="postDetail-position_info" v-html="postInfoDetail.position_info"></p>
      <p class="postDetail-url">
        <a :href="postInfoDetail.url" style="color: #5c7bd9; font-weight: bold; font-size: 16px" target="_blank">前往原网页</a>
      </p>
    </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import { EventBus } from "../utils/eventBus.js";
import GraphCategory from "../components/GraphCategory.vue";
import WorldNew from "../components/WorldNew.vue";
import MapIndustry from "../components/MapIndustry.vue";
import MapPatent from "../components/MapPatent.vue";
import MapTalent from "../components/MapTalent.vue";
import PatentCard from "../components/PatentCard.vue";
import ev from "../json/industry_link/ev.json";
import yiliao from "../json/industry_link/yiliao.json";
import yiliaoqixie from "../json/industry_link/yiliaoqixie.json";
import IndustryPatentEcharts from "../components/IndustryPatentEcharts.vue";
import IndustryTalentEcharts from "../components/IndustryTalentEcharts.vue";
import NewsKeywordCloud from "../components/NewsKeywordCloud.vue";
import CompanyPatentBar from "@/components/CompanyPatentBar.vue";
import CompanyPostBar from "@/components/CompanyPostBar.vue";
import PostCountBar from "@/components/PostCountBar.vue";
import CompanyDetailHori from "../components/CompanyDetailHori.vue";
import ScoreRadar from "../components/ScoreRadar.vue";
import Star from "@/components/Star.vue";
import IndustryFrontierMechanism from "@/components/IndustryFrontierMechanism.vue";
import NewsListsShow from "@/components/NewsListsShow.vue";
import OverviewMap from "@/components/OverviewMap.vue";
import RiskDistribution from "@/components/RiskDistribution.vue";
import RiskWarning from "@/components/RiskWarning.vue";
import IndustryPolicy from "@/components/IndustryPolicy.vue";
import BtnSubscribe from "@/components/BtnSubscribe.vue";
import EmptyTip from "@/components/EmptyTip.vue";

export default {
  components: {
    GraphCategory,
    WorldNew,
    MapIndustry,
    PatentCard,
    MapPatent,
    MapTalent,
    IndustryPatentEcharts,
    NewsKeywordCloud,
    CompanyPatentBar,
    CompanyPostBar,
    IndustryTalentEcharts,
    PostCountBar,
    CompanyDetailHori,
    ScoreRadar,
    Star,
    IndustryFrontierMechanism,
    NewsListsShow,
    OverviewMap,
    RiskDistribution,
    RiskWarning,
    IndustryPolicy,
    BtnSubscribe,
    EmptyTip,
  },
  watch: {
    tabRadio: {
      handler(newValue) {
        if (newValue == "首页") {
          EventBus.$emit("isIndustryStructure", true);
        } else {
          EventBus.$emit("isIndustryStructure", false);
        }
      },
    },
    //监听产业链的小tab挂载滚动监听
    current_tab: {
      handler(newValue) {
        if (newValue == '产业前沿') {
          this.$nextTick(() => {
            this.$refs.NewsListsShow.$el.addEventListener('scroll', (e) => {
              const scrollTop = e.target.scrollTop
              const clientHeight = e.target.clientHeight
              const scrollHeight = e.target.scrollHeight
              if (scrollTop + clientHeight >= (scrollHeight - 5)) { // X.X 修改滚动无加载 可能因为padding 因此需要减去一点点，我就减去5好了
                this.frontierParams.page++
                this.getIndustryFrontierDetail(this.domain_id_select)
              }
            })
          })
        } else if (newValue == '项目投资') {
          this.$nextTick(() => {
            this.$refs.investNest.$el.addEventListener('scroll', (e) => {
              const scrollTop = e.target.scrollTop
              const clientHeight = e.target.clientHeight
              const scrollHeight = e.target.scrollHeight
              if (scrollTop + clientHeight >= (scrollHeight - 5)) { // X.X 修改滚动无加载 可能因为padding 因此需要减去一点点，我就减去5好了
                this.investParams.page++
                this.getIndustryInvestmentDetail(this.domain_id_select);
              }
            })
          })
        } else if (newValue == '产业风险') {
          this.$nextTick(() => {
            this.$refs.riskWarning.$el.addEventListener('scroll', (e) => {
              const scrollTop = e.target.scrollTop
              const clientHeight = e.target.clientHeight
              const scrollHeight = e.target.scrollHeight
              if (scrollTop + clientHeight >= scrollHeight) {
                this.risk_params.page++
                this.getIndustryRisk(this.domain_id_select)
              }
            })
          })
        } else if (newValue == '产业政策') {
          this.$nextTick(() => {
            this.$refs.industryPolicy.$el.addEventListener('scroll', (e) => {
              const scrollTop = e.target.scrollTop
              const clientHeight = e.target.clientHeight
              const scrollHeight = e.target.scrollHeight
              if (scrollTop + clientHeight >= scrollHeight) {
                this.policyParams.page++
                this.getIndustryPolicy(this.domain_id_select)
              }
            })
          })
        }
      }
    },
    $route: {
      handler(newVal, oldVal) {
        console.log("$route", newVal);
        if (this.$route.query.from == "company_detail_hori") {
          console.log("node_link_list", this.$route.query);
          this.$nextTick(() => {
            this.node_link_list = JSON.parse(this.$route.query.query);
          });
          this.tabRadio = "产业链";
          this.$nextTick(() => {
            EventBus.$emit("isIndustryStructure", false);
          });
        } else if (newVal.query.from == "personal_subscribe") {
          let params = JSON.parse(sessionStorage.getItem("subscribe_node"));
          this.chooseIndustrySubscribe(params);
        } else if (Object.keys(this.$route.query).length == 0) {
          console.log("无高亮节点");
          this.$nextTick(() => {
            this.node_link_list = [];
          });
        }
      },
    },
  },
  data() {
    return {
      is_company: 0,
      has_perm: false,
      is_custom: false,
      frontierNewsLoading: false,
      frontierParams: {
        node_id: "",
        page: 1,
        page_size: 10,
        company_id: "",
      },
      investParams: {
        node_id: "",
        page: 1,
        page_size: 10,
      },
      risk_params: {
        node_id: "",
        page: 1,
        page_size: 10,
        risk_type: "",
      },
      policyParams: {
        node_id: "",
        page: 1,
        page_size: 10,
      },
      treeNode: "", //默认节点名称
      tabPosition: "头部企业", //产业玩家下的tab
      industryInvestmentDetailLoading: false,
      is_normal: 1, // 是否普通用户
      boxPage: 0,
      current_arrow_index: 0,
      node_search: false, // 是否搜索
      boxNumTotal: 0,
      boxNum: 3, // 产业链盒子数量
      searchQuery: "",
      optionListRadarCut: [],
      industryPolicyLoading: false,
      riskDistributionLoading: false,
      riskWarningLoading: false,
      industryPolicyData: [], // 产业政策
      riskWarningData: [], // 风险预警
      riskDistributionData: [], // 风险分布
      investmentNewsList: [], // 项目投资资讯industryPolicyData
      mapData: [], // 项目投资概况
      newsList: [], // 前沿科技资讯
      wordList: [], // 前沿技术方向关键词
      loadedNewsCount: 0,//滚动记录新闻数量
      mechainismLoading: false,
      frontierWordLoading: false,
      mechainismList: [], // 产业前沿机构列表
      current_tab: "产业玩家",
      linkTabList: ["产业玩家", "产业前沿", "项目投资", "产业风险", "产业政策"],
      linkTabListCustom: ["产业玩家"],
      tabList: ["首页", "产业生态", "产业链"],
      cancelLoading: false,
      subscribeLoading: false,
      domain_id_select: 1,
      subscribeNodeName: [],
      node_name_select: "新能源汽车",
      domain_range: -1,
      node_link_list: [],
      graphOptionRadarLoading: false,
      newsRelateProduct: {},
      postRelateProduct: {},
      patentRelateProduct: {},
      companyListLoading: true,
      industryImportantObjArr: [],
      industryImportant: [""],
      activeTab: "首页",
      wordCloudDataPatent: [],
      patent_listRival: [],
      maxCount: 0,
      scatterData: {
        companys_map: {},
        companys: [],
        years: [
          "2010",
          "2011",
          "2012",
          "2013",
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019",
          "2020",
          "2021",
        ],
        data: [],
      },
      radarTotal: 0,
      radarData: [0, 0, 0, 0, 0],
      postCountLoading: false,
      talentData: [],
      postLoading: false,
      talentEchartsData: {},
      talentCountLoading: false,
      tagList: [],
      productList: [],
      industryPaquList: [],
      chartDataLink: {},
      chartLinkShortName: "",
      onlySub: false,
      chartDataFinancial: [],
      chartDataProduct: {},
      chartProductYearList: [],
      detailTrTopVal: [],
      patentEchartsData: [],
      wordCloudData2: {},
      patentListData: [],
      postCount: {
        // 岗位招聘排行
        legend: [],
        data: [],
        name: {},
      },
      collapseActiveNames: ["1", "2", "3", "4", "5", "6"],
      post_list: [],
      post_params: {
        industry: "",
        company_id: "",
        page: 1,
        page_size: 10,
        industry_node: "",
        post_classification: "",
        filter_type: "相关公司岗位",
      },
      post_total: 0,
      link_industry: "",
      patent_list: [],
      postCompanyLoading: false,
      barLoading: false,
      downstreamLoading: false,
      patentLoading: false,
      patentScatterLoading: false,
      patentCountLoading: false,
      keywordLoading: false,
      downstreamCompanyList: [],
      downstreamCompanyListChina: [],
      downstreamCompanyListGlobal: [],
      industryDesc: "",
      industrySelected: "",
      domain_id: "",
      nodeDesc: "",
      tabRadio: "首页",
      tableLoading: false,
      treeLoading: false,
      compNameForLogo: "",
      companyInfo: {
        entity_name: "",
        telephone: "",
        website: "",
        email: "",
        office_addr: "",
        description: "",
        label: "",
        legal_representative: "",
        company_type: "",
        founded_date: "",
        regis_capital: "",
        regis_code: "",
        headquater: "",
        employees: "",
        name_en: "",
        listed: "",
        domain: "",
        stock_code: "",
        industry: "",
        company_profile: "",
      },
      patentTrendData: {},
      tableCost: [
        {
          part: "电动压缩机",
          price: "1200",
          in20: "3",
          out20: "2.7",
          in25: "2.8",
          out25: "3.6",
        },
        {
          part: "四通换向阀",
          price: "150",
          in20: "0.3",
          out20: "0.7",
          in25: "2.8",
          out25: "3.6",
        },
        {
          part: "换热器",
          price: "300",
          in20: "3",
          out20: "2.7",
          in25: "2.8",
          out25: "3.6",
        },
      ],
      postInfoDetail: {
        address: "",
        company_name: "",
        education: "",
        experience: "",
        job_type: "",
        label: "",
        name: "",
        num: "",
        other_label: "",
        position_info: "",
        region: "",
        release_date: "",
        salary: "",
        source: "",
        url: "",
        tags: [],
        keyword_tags: [],
      },
      showInfo: false,
      showCost: false,
      tabsValue: "1",
      isFirst: true,
      tableData: [],
      tableDataChina: [],
      tableDataGlobal: [],
      newsEchartsData: {},
      patentEchartsData: [],
      wordCloudData: [],
      permissions: "",
      nodes: [],
      mapTabMarket: 1,
      mapTabTech: 1,
      tab: 1,
      mapLatent: [],
      mapLatentMarket: [],
      mapLatentTech: [],
      worldList: [],
      worldListMarket: [],
      worldListTech: [],
      links: [],
      optionList: [],
      loading: false,
      showScoreDetail: false, // 是否展示评分项明细
      company_id: "",
      patentParams: {
        industry: "",
        domain_id: "",
        company_guogao_id: "",
        page: 1,
        page_size: 5,
        filter_type: "相关公司专利",
        inventor: "",
        company_id: "",
        applicant_id: "",
      },
      formData: {
        full_name: "xxx公司",
        is_listed: "1",
        board: "深圳主板",
        income: "",
      },
      companyPatentCount: {
        legend: [],
        data: [],
        name: {},
      },
      postCompanyCount: {
        // 产业人才排行
        legend: [],
        data: [],
        name: {},
      },
      postCount: {
        // 岗位招聘排行
        legend: [],
        data: [],
        name: {},
      },
      links_position: {
        upUpStream: [],
        upStream: [],
        mediumStream: [],
        downStream: [],
      },
      positionLoading: false,
      showPost: false,
      newsListCompany: [],
      companyNewsParams: {
        page: 1,
        page_size: 10,
        // company_id: '',
        company: "",
        domain_id: "1",
        industry: "",
        news_type: "",
      },
      // 三大类型企业分页器配置
      companyTypePageSize: 20,
      companyTypePage: 1,
      companyTypeTotal: 0,
    };
  },
  computed: {
    isOrNot() {
      if (this.formData.is_listed == 1) {
        return "是";
      } else {
        return "否";
      }
    },
  },
  // watch: {
  //   "$route": {
  //     handler(newVal, oldVal) {
  //       console.log("$route", newVal)
  //     }
  //   }
  // },
  methods: {
    //打开指定节点
    findNode() {
      console.log('findNode');
      this.$refs.graphCategory.focusOnNode('乘用车')
    },
    //下拉滚动方法
    // handleScroll(e){
    //   console.log('滚动到底部了',e);
    // },
    // 头部、潜优、新兴企业分页器配置
    handleCompanyTypeSizeChange(val) {
      console.log("当前页面size：", val);
      this.companyTypePageSize = val;
      this.getCompanyScore(this.tabPosition);
    },
    handleCompanyTypePageChange(val) {
      console.log("1212当前页面page：", val);
      this.companyTypePage = val;
      this.getCompanyScore(this.tabPosition);
    },
    setNodeCompany() {
      this.is_company = cookie.getCookie("is_company") || 0;
    },
    // is_industry_graph(){
    //   let new_industry_graph = sessionStorage.getItem('industry_graph')
    //   console.log('new_industry_graph',new_industry_graph)
    //   if(new_industry_graph == null){
    //     console.log('new_industry_graph is null')
    //     new_industry_graph = cookie.getCookie('industry_graph')
    //     console.log('new_industry_graph:',new_industry_graph)
    //     sessionStorage.setItem('industry_graph',new_industry_graph)
    //   }
    // },
    getHasPerm() {
      let vip_perm = JSON.parse(cookie.getCookie("vip_perm"));
      vip_perm.forEach((v) => {
        if (v.codename == "kjld_cytp") {
          this.has_perm = v.has_perm;
        }
      });
    },
    handleClickCompany(company_id) {
      this.frontierParams.company_id = company_id;
      this.newsList = []
      this.getIndustryFrontierDetail(this.domain_id_select);
    },
    chooseQY(val) {
      console.log("选择其他类型企业：", val, this.tabPosition, this.treeNode);
      this.getCompanyScore(this.tabPosition);
    },
    // 响应点击产业风险分类
    handleClickRisk(type) {
      this.risk_params.page = 1;
      this.risk_params.risk_type = type;
      this.getIndustryRisk();
    },
    // 获取产业图谱选项
    getIndustryGraphOption() {
      console.log("！！！！！获取产业图谱选项11");
      this.optionList = [];
      this.industryImportant = [];
      this.industryImportantObjArr = [];
      let industry_graph_permission = cookie.getCookie("industry_graph");
      api.getIndustryGraphOption().then((res) => {
        console.log("!!!!!!!!!!!!!!!!!!option_res：", res);
        // this.optionList = res.data;
        // this.industryImportant.push(res.data[0].name)
        // if (this.patentParams.industry == "ele_car") {
        res.data[0].option_list.forEach((i) => {
          this.industryImportant.push(i.name);
          this.industryImportantObjArr.push(i);
        });
        console.warn("获取产业图谱选项");
        console.warn(this.industryImportant);
        // }

        // console.log("industryImportant", this.industryImportant);
        let res_data = JSON.parse(JSON.stringify(res.data));
        res.data.forEach((r, idx) => {
          // if (industry_graph_permission.indexOf(r.code) != -1) {
          //   this.optionList.push(r);
          // }
          r.option_list.forEach((o, index) => {
            if (industry_graph_permission.indexOf(o.code) == -1) {
              //   this.optionList.push(o);
              // } else {
              // console.log("else", o);
              // res_data[idx].option_list.splice(index, 1)
              this.deleteArrayObject(res_data[idx].option_list, o.code);
              // console.log("splice", res_data[idx]);
            }
          });
        });
        res_data.forEach((r) => {
          if (r.option_list.length > 0) {
            this.optionList.push(r);
          }
        });
        // this.optionList = res_data
        console.log("industryImportant", this.industryImportant);
        console.log("optionList", this.optionList);
        console.log("res_data", res_data);
      });
    },

    // 产业图谱首页盒子
    setBoxStyle(i) {
      if (i.is_online == 0) {
        return "filter: brightness(60%);";
      } else {
        if (i.clickable == true || this.has_perm == true) {
          return "";
        } else {
          return "filter: brightness(60%);";
        }
      }
    },
    // 响应点击产业链box方向键
    handleClickArrow(flag) {
      // flag 0-左   1-右
      if (flag === 1) {
        this.current_arrow_index += 1;
        // this.showMoreIndustryBox()
      } else {
        this.current_arrow_index -= 1;
      }
      console.log("响应点击产业链box方向键", this.current_arrow_index);
    },
    // 监听用户订阅更新
    listenUpdateSubscribe() {
      EventBus.$on("updateSubscribe", (data) => {
        this.subscribeNodeName = JSON.parse(
          sessionStorage.getItem("subscribeNodeName")
        );
      });
    },
    // 监听点击 标题【产业图谱】
    listenClickToFirst() {
      EventBus.$on("clickToFirst", (data) => {
        this.tabRadio = "首页";
        console.log("进入监听标题点击：", this.tabRadio);
      });
    },
    // 搜索产业链
    handleNodeSearch(val) {
      this.current_arrow_index = 0;
      let options = JSON.parse(JSON.stringify(this.optionListRadar));
      let result = []
      if (val) {
        this.node_search = true;
        let option_list = options[0].option_list.filter(
          (x) => x.name.indexOf(val) != -1
        );
        this.boxNumTotal = option_list.length;
        this.boxPage = Math.ceil(this.boxNumTotal / 12); // 每页12个box
        for (let i = 0; i < this.boxPage; i++) {
          result[i] = {
            option_list: option_list.slice(i * 12, (i + 1) * 12),
          };
        }
        this.optionListRadarCut = [...result]
        console.log(
          "搜索产业链 this.optionListRadarCut：",
          this.optionListRadarCut
        );
      } else {
        console.log('search:没有');
        this.node_search = false;
        // this.boxNum = 3;
        // this.optionListRadarCut[0].option_list =
        //   this.optionListRadarCut[0].option_list.slice(0, 3);
        this.boxNumTotal = options[0].option_list.length;
        this.boxPage = Math.ceil(this.boxNumTotal / 12); // 每页12个box
        for (let i = 0; i < this.boxPage; i++) {
          this.optionListRadarCut[i] = {
            option_list: options[0].option_list.slice(i * 12, (i + 1) * 12),
          };
        }
      }
    },
    // 点击加载更多产业链
    // showMoreIndustryBox() {
    //   let lastNum = this.boxNum
    //   this.boxNum += 12;
    //   this.optionListRadarCut = JSON.parse(
    //     JSON.stringify(this.optionListRadar)
    //   );
    //   this.optionListRadarCut[0].option_list =
    //     this.optionListRadarCut[0].option_list.slice(lastNum, this.boxNum);
    // },
    // 点击加载更多
    showMoreCompany() {
      this.$router.push("/company_search/?query=" + this.node_name_select);
      // this.$router.push("/company_search")
    },
    // 点击产业选项
    handleClickIndustryBox(i) {
      console.log("!!!!!!!点击产业选项", i);
      let vip_perm = JSON.parse(cookie.getCookie("vip_perm"));
      let has_perm = false;
      vip_perm.forEach((v) => {
        if (v.codename == "kjld_cytp") {
          has_perm = v.has_perm;
        }
      });

      if (has_perm) {
        console.log("有权限");
        if (i.clickable == true) {
          sessionStorage.setItem("index_industry", JSON.stringify(i));
          this.$store.commit("changeIndexIndustry", i.name);
          EventBus.$emit("index_industry", i);
          EventBus.$emit("industry_drop", i);
          console.log("emit [index_industry]", i);
          this.domain_id = i.domain_id
          this.patentParams.industry = i.industry_en;
          this.getIndustryPosition();
          this.getIndustryIntroduction();
          // this.$router.push("/industry_structure");
          // this.showPosition = true
          // this.$router.push("/category_graph?from=industry_structure")
          this.tabRadio = "产业生态";
        } else {
          this.$message.warning(
            "该账号暂无此产业链权限，请联系管理员开通VIP权限！"
          );
        }
      } else {
        console.log("无权限");
        if (i.clickable == true) {
          sessionStorage.setItem("index_industry", JSON.stringify(i));
          this.$store.commit("changeIndexIndustry", i.name);
          EventBus.$emit("index_industry", i);
          EventBus.$emit("industry_drop", i);
          console.log("emit [index_industry]", i);
          this.patentParams.industry = i.industry_en;
          this.getIndustryPosition();
          // this.$router.push("/industry_structure");
          // this.showPosition = true
          // this.$router.push("/category_graph?from=industry_structure")
          this.tabRadio = "产业生态";
        } else {
          let dianlian = cookie.getCookie("dianlian_token");
          if (dianlian) {
          }
          this.$message.warning(
            "该账号暂无此产业链权限，请联系管理员开通VIP权限！"
          );
        }
      }
    },
    // 获取产业选项
    getIndustryGraphOptionRadar() {
      let params = {
        username: cookie.getCookie("account"),
      };
      this.optionListRadar = [];
      this.graphOptionRadarLoading = true;
      api
        .getIndustryGraphOptionRadar(params)
        .then((res) => {
          console.log("getIndustryGraphOptionRadar", res);
          // this.optionList = res.data
          // let industry_graph = cookie.getCookie("industry_graph");
          // console.log('getItem industry_graph')
          let industry_graph = JSON.parse(
            sessionStorage.getItem("industry_graph")
          );
          // let industry_graph_array = industry_graph.split(",");

          let industry_graph_array = [];
          console.log("industry_graph", industry_graph);
          industry_graph.forEach((i) => {
            industry_graph_array.push(i.codename);
          });
          console.log("industry_graph_array", industry_graph_array);
          if (this.env != "pro") {
            // 判断环境（非生产环境不论is_online为多少都展示）
            let arr_tmp = {
              industry_type: "新能源汽车",
              option_list: [],
            };
            res.data[0].option_list.forEach((i) => {
              // if (industry_graph_array.indexOf(i.code) != -1) {
              arr_tmp.option_list.push(i);
              // }
            });
            this.optionListRadar.push(arr_tmp);
            let options = JSON.parse(JSON.stringify(this.optionListRadar));
            let option_permission = JSON.parse(
              sessionStorage.getItem("industry_graph")
            );
            console.log("option_permission", option_permission);
            let option_list_origin = options[0].option_list
            options[0].option_list = []
            option_list_origin.forEach(o => { // 正式环境只展示有上线的项
              if (o.is_online == 1) {
                options[0].option_list.push(o)
              }
            })
            this.boxNumTotal = options[0].option_list.length;
            this.boxPage = Math.ceil(this.boxNumTotal / 12); // 每页12个box
            for (let i = 0; i < this.boxPage; i++) {
              this.optionListRadarCut[i] = {
                option_list: options[0].option_list.slice(i * 12, (i + 1) * 12),
              };
            }
            console.log("optionListRadar", this.optionListRadar);
            console.log("optionListRadarCut", this.optionListRadarCut);
          } else {
            // 判断环境（生产环境只展示is_online=1的项）
            // let arr_tmp = {
            //   industry_type: "新能源汽车",
            //   option_list: [],
            // };
            // res.data[0].option_list.forEach((i) => {
            //   if (
            //     i.is_online == 1 &&
            //     industry_graph_array.indexOf(i.code) != -1
            //   ) {
            //     arr_tmp.option_list.push(i);
            //   }
            // });
            // this.optionListRadar.push(arr_tmp);
            let arr_tmp = {
              industry_type: "新能源汽车",
              option_list: [],
            };
            res.data[0].option_list.forEach((i) => {
              // if (industry_graph_array.indexOf(i.code) != -1) {
              //   arr_tmp.option_list.push(i);
              // }
              industry_graph.forEach((industry) => {
                if (i.code == industry.codename && i.is_online == 1) {
                  i.has_perm = industry.has_perm;
                  arr_tmp.option_list.push(i);
                }
              });
            });
            this.optionListRadar.push(arr_tmp);
            let options = JSON.parse(JSON.stringify(this.optionListRadar));
            let option_permission = JSON.parse(
              sessionStorage.getItem("industry_graph")
            );
            console.log("option_permission", option_permission);
            this.boxNumTotal = options[0].option_list.length;
            this.boxPage = Math.ceil(this.boxNumTotal / 12); // 每页12个box
            options[0].option_list.forEach((o) => {
              o.has_perm = false;
              option_permission.forEach((p) => {
                if (p.codename == o.code) {
                  o.has_perm = p.has_perm;
                }
              });
            });
            for (let i = 0; i < this.boxPage; i++) {
              this.optionListRadarCut[i] = {
                option_list: options[0].option_list.slice(i * 12, (i + 1) * 12),
              };
            }
            console.log("optionListRadar", this.optionListRadar);
            console.log("optionListRadarCut", this.optionListRadarCut);
          }

          this.graphOptionRadarLoading = false;
        })
        .catch((err) => {
          console.log("graphOptionRadarERROR", err);
          this.graphOptionRadarLoading = false;
        });
    },
    // 点击产业链下的tab
    handleClickLinkTab(val) {
      this.current_tab = val;
      this.frontierParams.company_id = "";
      if (val == "产业前沿") {
        this.getIndustryFrontierOrganization(this.domain_id_select);
        this.getIndustryFrontierWordClouds(this.domain_id_select);
        this.getIndustryFrontierDetail(this.domain_id_select);
      } else if (val == "项目投资") {
        this.getIndustryInvestmentRegion(this.domain_id_select);
        this.getIndustryInvestmentDetail(this.domain_id_select);
      } else if (val == "产业风险") {
        this.getIndustryRiskTags(this.domain_id_select);
        this.getIndustryRisk(this.domain_id_select);
      } else if (val == "产业政策") {
        this.getIndustryPolicy(this.domain_id_select);
      } else if (val == "产业玩家") {
        this.getCompanyScore(this.tabPosition);
      }
    },
    handleClickDiyTab(val) {
      this.tabRadio = val;
      console.log("handleClickDiyTab", val);
      if (val == "产业链") {
        this.getIndustryTree(this.domain_id_select);
        let tmp = this.links;
        this.links = [];
        this.$nextTick(() => {
          this.links = tmp;
        });
      }
    },
    handleClickTab(val) {
      console.log("handleClickTab", val);
      if (val == "产业链") {
        this.getIndustryTree(this.domain_id_select);
        let tmp = this.links;
        this.links = [];
        this.$nextTick(() => {
          this.links = tmp;
        });
      }
    },
    // 获取产业链节点介绍
    getIndustryDesc() {
      let params = {
        industry_id: this.domain_id,
      };
      api.getIndustryDesc(params).then((res) => {
        this.nodeDesc = res.data;
      });
    },
    // 获取产业市场简介
    getIndustryIntroduction() {
      let params = {
        industry_id: this.domain_id,
      };
      api.getIndustryIntroduction(params).then((res) => {
        console.log("产业简介", res);
        this.industryDesc = res.data;
      });
    },
    // 个人订阅点击订阅节点
    chooseIndustrySubscribe(val) {
      console.log("个人订阅点击订阅节点", val);
      val.is_cyst = true;
      this.tabRadio = "产业链";
      if (val.root_id == "" || val.root_id == undefined) {
        val.root_name = val.name;
      }
      this.treeNode = val.name;
      this.$store.commit("changeIndexIndustry", val.name);
      EventBus.$emit("industry_drop", val);
    },
    // 点击产业产业生态
    chooseIndustry(val) {
      console.log("点击产业产业生态val", val);
      val.is_cyst = true; // 标记是产业生态
      console.log("点击产业产业生态", val.name == this.industrySelected);
      if (val.name == this.industrySelected) {
        this.tabRadio = "产业链";
        val.id = val.node_id;
        EventBus.$emit("industry_drop", val);
        console.log("valval", val);
        this.handleClickTab("产业链");
      } else {
        this.industryImportantObjArr.forEach((i) => {
          // EventBus.$emit("index_industry", val)
          if (i.name == val.name) {
            console.log("产业生态", i);
            sessionStorage.setItem("index_industry", JSON.stringify(i));
            EventBus.$emit("index_industry", i);
            this.$store.commit("changeIndexIndustry", i.name);
          }
        });
      }
    },
    // // 监听公司详情-企业舆情图谱点击方块
    // listenDetailNewsProduct() {
    //   EventBus.$on("detail_news_product", data => {
    //     console.log("detail_news_product", data)
    //     this.companyNewsParams.domain_id = data
    //     this.companyNewsParams.page = 1
    //     this.newsListCompany = []
    //     this.getCompanyNewsByProduct()
    //   })
    // },
    // 监听公司详情-专利布局图谱点击方块
    listenDetailPatentProduct() {
      EventBus.$on("detail_patent_product", (data) => {
        this.patentParams.page = 1;
        this.patentParams.filter_type = "相关产品专利";
        this.patentParams.domain_id = data;
        this.patent_listRival = [];
        // this.getPatentList()
        this.getIndustryPatentRival();
      });
    },
    // 监听点击下拉
    listenIndustryDrop() {
      EventBus.$on("industry_drop", (data) => {
        console.log("##########, listenIndustryDrop", data);
        if (this.$route.path != "/category_graph") {
          return;
        }
        this.is_custom = false;
        console.log("industry_drop", data);
        this.treeNode = data.name;
        this.current_tab = "产业玩家";
        this.companyNewsParams.domain_id = data.id;
        this.domain_id = data.id ? data.id : data.domain_id;
        // this.domain_id = data.root_id ? data.root_id : data.node_id;
        this.getIndustryDesc();
        // if (this.tabRadio == "首页") {
        if (this.$route.path == "/category_graph") {
          console.log("is_search", data.is_search);
          if (
            data.is_search == true ||
            data.is_cyst == true ||
            data.is_drop == true
          ) {
            this.tabRadio = "产业链";
          } else {
            this.tabRadio = "首页";
          }
        } else {
          this.tabRadio = "产业链";
        }

        // this.handleClickTab("产业链");
        // }
        if (this.tabRadio == "产业生态") {
          this.patentParams.industry = data.industry_en;
          this.getIndustryPosition();
          this.getIndustryIntroduction();
        }
        console.log("监听点击下拉", data);
        this.node_name_select = data.name;
        this.domain_id_select = data.id ? data.id : data.domain_id;
        // this.domain_id_select = data.root_id ? data.root_id : data.node_id;
        this.node_link_list = data.node_link_list;
        if (this.tabRadio == "最新舆情") {
          this.newsList = [];
          this.filter_type = "全部";
          this.companyCheckBox = [];
          this.$refs["checkbox-company"].style.height = "30px";
          this.style_height = "30px";
          this.companys = [];
          this.getNewsCompany();

          if (this.companyNewsParams.news_type != "司法公告") {
            this.getCompanyNews();
          } else {
            this.getEventRisk();
          }
        } else if (this.tabRadio == "热点事件") {
          // this.hotNewsLoading = true
          this.newsListHot = [];
          this.getHotNews();
        }
        this.industrySelected = data.name;
        console.log("patentParams.industry", this.patentParams.industry);
        if (this.tabRadio == "产业链") {
          // let root_name = data.root_name ? data.root_name : data.name
          let root_id = data.root_id ? data.root_id : data.id;
          if (!root_id) {
            root_id = data.domain_id;
          }
          console.log("点击下拉刷新：", root_id);
          if (Object.keys(data).includes("is_search") || Object.keys(data).includes("is_drop")) {
            this.getIndustryTree(root_id, data.is_search || data.is_drop);
            console.log('走向if');
          } else {
            this.getIndustryTree(root_id);
            console.log('走向ife');
          }
          this.getCompanyScore(this.tabPosition);
        }
      });
    },
    listenIndexIndustry() {
      EventBus.$on("index_industry", (data) => {
        this.patentParams.industry = data.industry_en;
        this.domain_id = data.domain_id;
        this.industrySelected = data.name;
        this.getIndustryPosition();
        this.getIndustryIntroduction();
        this.getIndustryTree(data.domain_id);
        this.getCompanyScore(this.tabPosition);
      });
    },
    // 响应子组件
    handleShowMoreNews() {
      this.companyNewsParams.page += 1;
      // this.getCompanyNews();
      this.getCompanyNewsByProduct();
    },
    handleShowMorePost() {
      this.post_params.page += 1;
      this.getPostInfo();
    },
    handleShowMorePatentRival() {
      this.patentParams.page += 1;
      this.getIndustryPatentRival();
    },
    // 获取企业舆情（按照产品）
    getCompanyNewsByProduct() {
      api.getCompanyNewsByProduct(this.companyNewsParams).then((res) => {
        console.log("产业舆情", res);
        res.data.forEach((i) => {
          let company_list = [];
          i.company_list.forEach((c) => {
            company_list.push(c);
          });
          // i.company_list_arr = [...new Set(company_list)]
          // i.company_list_arr = this.unique(company_list, "short_name");
          i.company_list_arr_total = this.unique(company_list, "short_name");
          i.company_list_arr = i.company_list_arr_total.slice(0, 10);
          i.company_is_hot = [];
          i.company_is_hot_total = [];
          i.company_list_arr_total.forEach((c) => {
            if (c.is_hot == 1) {
              i.company_is_hot_total.push(c);
            }
          });
          i.company_is_hot = i.company_is_hot_total.slice(0, 10);
          if (i.company_list_arr_total.length > 10) {
            i.company_list_arr.push({
              short_name: "...",
            });
          }
          if (i.company_is_hot_total.length > 10) {
            i.company_is_hot.push({
              short_name: "...",
            });
          }
          // console.log("company_list", company_list)
          // console.log("title_classify_tags", i.title_classify_tags)
          i.title_classify_tags.forEach((t, index) => {
            if (
              index > 0 &&
              index % 2 == 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 2]
            ) {
              i.title_classify_tags[index] = "";
            }
            if (
              index % 2 != 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 1]
            ) {
              i.title_classify_tags[index] = "";
            }
          });
          this.newsListCompany.push(i);
        });
      });
    },
    // 获取企业舆情
    getCompanyNews() {
      console.log("-----------------------获取产业舆情");
      // this.companys = []
      api.getCompanyNews(this.companyNewsParams).then((res) => {
        console.log("产业舆情", res);
        res.data.forEach((i) => {
          let company_list = [];
          i.company_list.forEach((c) => {
            company_list.push(c);
          });
          // i.company_list_arr = [...new Set(company_list)]
          i.company_list_arr = this.unique(company_list, "short_name");
          // console.log("company_list", company_list)
          // console.log("title_classify_tags", i.title_classify_tags)
          i.title_classify_tags.forEach((t, index) => {
            if (
              index > 0 &&
              index % 2 == 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 2]
            ) {
              i.title_classify_tags[index] = "";
            }
            if (
              index % 2 != 0 &&
              i.title_classify_tags[index] == i.title_classify_tags[index - 1]
            ) {
              i.title_classify_tags[index] = "";
            }
          });
          this.newsListCompany.push(i);
        });
      });
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      // this.tagLoading = true;
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
          // 正式环境

          if (res.data.product_detail) {
            this.tagList = res.data.product_detail.split("；");
            this.tagList.pop();
            this.tagList = [...new Set(this.tagList)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }

          // this.tagLoading = false;
        })
        .catch((err) => {
          // this.tagLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.linkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
        })
        .catch((err) => {
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 加载更多专利
    showMorePatent() {
      this.patentParams.page += 1;
      console.log("page", this.patentParams.page);
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        // this.showMoreText = "已无更多"
      }
    },
    handleCollapseActive(val) {
      this.collapseActiveNames = val;
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach((i) => {
          this.patentListData.push(i);
        });
        console.log("patentListData", this.patentListData);
        // this.patentLoading = false;
      });
    },
    // 监听树节点点击
    listenTreeClick() {
      EventBus.$on("tree_click", (data) => {
        console.log("树点击跳转传值", data);
        if (data.is_custom) {
          this.is_custom = true;
        } else {
          this.is_custom = false;
        }
        console.log("tabRadio：", this.tabRadio);
        console.log("is_custom：", this.is_custom);
        if (this.$route.path != "/category_graph") {
          return;
        }
        console.log("监听树节点点击", data);
        // this.domain_id = data.node_id;
        // this.getCompanyScore();
        let params = {
          // node_name: data.name,
          node_id: data.node_id,
        };
        api.findRootNode(params).then((res) => {
          console.log("查找最近的根节点", res.data[0]);
          this.current_tab = "产业玩家";
          this.companyNewsParams.domain_id = res.data[0].node_id;
          this.domain_id = res.data[0].node_id
            ? res.data[0].node_id
            : res.data[0].domain_id;
          // this.domain_id = res.data[0].root_id ? res.data[0].root_id : res.data[0].node_id;
          this.getIndustryDesc();
          this.tabRadio = "产业链";
          // this.handleClickTab("产业链");
          if (this.tabRadio == "产业生态") {
            this.patentParams.industry = res.data[0].industry_en;
            this.getIndustryPosition();
            this.getIndustryIntroduction();
          }
          console.log("监听点击下拉", res.data[0]);
          this.node_name_select = res.data[0].name;
          this.domain_id_select = res.data[0].node_id
            ? res.data[0].node_id
            : res.data[0].domain_id;
          // this.domain_id_select = res.data[0].root_id ? res.data[0].root_id : res.data[0].node_id;
          this.node_link_list = res.data[0].node_link_list;
          if (this.tabRadio == "最新舆情") {
            this.newsList = [];
            this.filter_type = "全部";
            this.companyCheckBox = [];
            this.$refs["checkbox-company"].style.height = "30px";
            this.style_height = "30px";
            this.companys = [];
            this.getNewsCompany();

            if (this.companyNewsParams.news_type != "司法公告") {
              this.getCompanyNews();
            } else {
              this.getEventRisk();
            }
          } else if (this.tabRadio == "热点事件") {
            // this.hotNewsLoading = true
            this.newsListHot = [];
            this.getHotNews();
          }
          // this.$router.push("/category_graph") // 刷新去除url参数
          // this.node_link_list = []; // 切换节点清除高亮
          // if (this.$route.path != '/category_graph') {
          //   console.log("不监听")
          //   return
          // }
          this.industrySelected = res.data[0].name;
          // this.industryImportantObjArr.forEach((i) => {
          //   if (i.name == res.data[0].name) {
          //     this.domain_id = i.domain_id;
          //     this.companyNewsParams.domain_id = i.domain_id;
          //     this.patentParams.industry = i.industry_en;
          //   }
          // });
          console.log("domain_id", this.domain_id);
          console.log(
            "companyNewsParams.domain_id",
            this.companyNewsParams.domain_id
          );
          console.log("patentParams.industry", this.patentParams.industry);
          if (this.tabRadio == "产业链") {
            this.getIndustryTree(res.data[0].root_id, true);
            this.getCompanyScore(this.tabPosition);
          }
        });
      });
    },
    // 监听招聘岗位排行点击
    listenCompanyPostRank() {
      EventBus.$on("company_post_rank", (data) => {
        this.post_params.post_classification = data;
        this.post_params.page = 1;
        this.post_params.company_id = "";
        this.getPostInfo();
      });
    },
    // 获取招聘散点分布
    getPostScatter(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.talentData = [];
      api.getPostScatter(params).then((res) => {
        // console.log("招聘散点", res)
        this.talentData = res.data;
      });
    },
    // 点击招聘信息
    showPostDetail(val) {
      // console.log(val)
      this.getPostDetail(val.id);
      this.postInfoDetail = {};
      this.postInfoDetail.tags = [];
      this.postInfoDetail.keyword_tags = [];
      this.showPost = true;
    },
    // 获取招聘信息详情
    getPostDetail(id) {
      let params = {
        id: id,
      };
      api.getPostDetail(params).then((res) => {
        // console.log(res)
        this.postInfoDetail = res.data;
        this.postInfoDetail.tags = res.data.other_label.split("|");
        this.postInfoDetail.keyword_tags = res.data.key_word.split("|");
      });
    },
    // 获取公司招聘排行
    getPostRank(node) {
      let params = {
        industry: this.patentParams.industry,
        industry_node: node,
      };
      this.postCompanyLoading = true;
      this.postCompanyCount.legend = [];
      this.postCompanyCount.data = [];
      this.postCompanyCount.name = {};
      api
        .getPostRank(params)
        .then((res) => {
          // console.log("招聘排行", res)
          res.data.forEach((i) => {
            let legend_item =
              i.short_name.length > 4
                ? i.short_name.substring(0, 4) + "..."
                : i.short_name;
            this.postCompanyCount.legend.push(legend_item);
            this.postCompanyCount.name[legend_item] = i.full_name;
            // this.postCompanyCount.data.push(i.num);
            this.postCompanyCount.data.push({
              value: i.count,
              company_id: i.company_id,
            });
          });
          this.postCompanyLoading = false;
        })
        .catch((err) => {
          this.postCompanyLoading = false;
        });
      // console.log("postCompanyCount", this.postCompanyCount)
    },
    // 分页器变化
    handlePostPageChange(val) {
      // console.log("分页", val)
      this.post_params.page = val;
      this.getPostInfo();
    },
    // 获取公司招聘信息
    getPostInfo() {
      api
        .getPostInfo(this.post_params)
        .then((res) => {
          console.log("获取公司招聘信息", res.data);
          // this.post_list = res.data;
          res.data.forEach((i) => {
            this.post_list.push(i);
          });
          // this.post_total = res.count;
        })
        .catch((err) => { });
    },
    // 过滤类型切换
    handleTypeChange(val) {
      // console.log(val);
      if (this.patentParams.domain_id) {
        this.patent_list = [];
        this.getIndustryPatent();
      }
    },
    // 获取产业产业生态
    async getIndustryPosition() {
      let params = {
        industry: this.patentParams.industry,
      };
      this.positionLoading = true;
      await api.getIndustryPosition(params).then((res) => {
        console.log("111111111111产业生态：", res);
        this.links_position = {
          upUpStream: [],
          upStream: [],
          mediumStream: [],
          downStream: [],
        };
        res.data.forEach((i) => {
          if (i.chain == 0) {
            if (!i.father_id) {
              this.links_position.upUpStream.push({
                name: i.name,
                id: i.id,
                node_id: i.node_id,
                children: [],
              });
            }
          }
          if (i.chain == 1) {
            if (!i.father_id) {
              this.links_position.upStream.push({
                name: i.name,
                id: i.id,
                node_id: i.node_id,
                children: [],
              });
            }
          }
          if (i.chain == 2) {
            if (!i.father_id) {
              this.links_position.mediumStream.push({
                name: i.name,
                id: i.id,
                node_id: i.node_id,
                children: [],
              });
            }
          }
          if (i.chain == 3) {
            if (!i.father_id) {
              this.links_position.downStream.push({
                name: i.name,
                id: i.id,
                node_id: i.node_id,
                children: [],
              });
            }
          }
        });
        res.data.forEach((i) => {
          if (i.chain == 0 && i.father_id) {
            this.links_position.upUpStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 1 && i.father_id) {
            this.links_position.upStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 2 && i.father_id) {
            this.links_position.mediumStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
          if (i.chain == 3 && i.father_id) {
            this.links_position.downStream.forEach((j) => {
              if (j.id == i.father_id) {
                j.children.push({
                  name: i.name,
                });
              }
            });
          }
        });
        console.log("数据格式化", this.links_position);
        // setTimeout(() => {
        //   this.lineInit()
        // }, 2000)

        this.positionLoading = false;
      });
      // if (this.patentParams.industry == "ele_car") {
      setTimeout(() => {
        console.log(this);
        this.lineInit();
      }, 500);
      // }
    },
    // 点击查看评分项明细
    async handleCheckDetail() {
      this.showScoreDetail = await true;
      // setTimeout(() => {
      //   document.getElementsByClassName(
      //     "el-table__body-wrapper is-scrolling-middle"
      //   )[0].scrollLeft = 0;
      // }, 500)
    },
    // 获取公司评分列表
    getCompanyScore(ctype) {
      console.log("获取公司评分列表");
      // 判断domain_id是否为undifined
      let node_id = "";
      if (this.domain_id == undefined) {
        console.log(
          "domain undifined",
          this.domain_id,
          this.companyNewsParams.domain_id
        );
        node_id = this.companyNewsParams.domain_id;
      } else {
        console.log(
          "domain indifined",
          this.domain_id,
          this.companyNewsParams.domain_id
        );
        node_id = this.domain_id;
      }
      let params = {
        node_id: node_id,
        account: cookie.getCookie("account"),
        ctype: ctype,
        node: this.industrySelected,
        page: this.companyTypePage,
        page_size: this.companyTypePageSize,
      };
      console.log("params:", params);
      // this.downstreamLoading = true;
      this.companyListLoading = true;
      let latentCompAreaList = [];
      let globalCompAreaList = [];
      this.mapLatentMarket = [];
      this.worldListMarket = [];
      // this.downstreamCompanyList = [];
      // this.downstreamCompanyListChina = [];
      // this.downstreamCompanyListGlobal = [];
      api
        .getCompanyScore(params)
        .then((res) => {
          // this.mapLatentMarket = []
          // this.worldListMarket = []
          // this.companyTypePage = 1;
          this.domain_range = res.domain_range;
          this.downstreamCompanyList = [];
          this.downstreamCompanyListChina = [];
          this.downstreamCompanyListGlobal = [];
          this.companyTypeTotal = res.count;
          if (res.data.length > 0) {
            res.data.forEach((i) => {
              if (i.short_name) {
                i.name = i.short_name;
              } else {
                i.name = i.full_name;
              }
              //合并名录标签
              i.allLabel = i.directory_label + "；" + i.company_type_tags;
              // console.log('allLabel',i.allLabel)
              i.allLabel = i.allLabel
                .replaceAll("null", "")
                .replaceAll("；", " ");
              //标签去重
              i.allLabel = Array.from(new Set(i.allLabel.split(" ")));
              if (i.allLabel[0] == "") {
                i.allLabel.shift();
              }
              if (i.allLabel[i.allLabel.length - 1] == "") {
                i.allLabel.pop();
              }
              // .toString().replaceAll(',',' ')
              if (i.founded_time != null) {
                i.founded_time = i.founded_time.split("T")[0];
              }

              // console.log('allLabel',i.allLabel)
              // this.downstreamCompanyList.push(i);
              if (i.country == "中国") {
                this.downstreamCompanyListChina.push(i);
              } else {
                this.downstreamCompanyListGlobal.push(i);
              }
            });
            if (this.mapTabMarket == 1) {
              this.downstreamCompanyList = this.downstreamCompanyListChina;
            } else {
              this.downstreamCompanyList = this.downstreamCompanyListGlobal;
            }
            let arrProvince = new Set();
            let arrCountry = new Set();
            res.data.forEach((i) => {
              // console.log("loc_province", i.loc_province);
              if (i.loc_province && i.country == "中国") {
                arrProvince.add(
                  i.loc_province.replace("省", "").replace("市", "")
                );
              }
              if (i.country && i.country != "中国") {
                arrCountry.add(i.country);
              }
            });
            arrProvince.forEach((i) => {
              latentCompAreaList.push({
                loc_province: i,
                value: 0,
              });
            });
            arrCountry.forEach((i) => {
              globalCompAreaList.push({
                name: i,
                value: 0,
              });
            });
            res.data.forEach((item) => {
              for (let i = 0; i < latentCompAreaList.length; i++) {
                if (
                  latentCompAreaList[i].loc_province &&
                  item.loc_province &&
                  latentCompAreaList[i].loc_province ==
                  item.loc_province.replace("省", "").replace("市", "")
                ) {
                  latentCompAreaList[i].value += 1;
                }
              }
              for (let i = 0; i < globalCompAreaList.length; i++) {
                if (globalCompAreaList[i].name == item.country) {
                  globalCompAreaList[i].value += 1;
                }
              }
            });
          }
          this.companyListLoading = false;
        })
        .catch((err) => {
          this.downstreamCompanyList = [];
          this.companyListLoading = false;
          console.error(err);
        });
      this.mapLatentMarket = latentCompAreaList;
      this.worldListMarket = globalCompAreaList;
    },
    // 获取产业对应公司的专利排行
    getCompanyPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.companyPatentCount.legend = [];
      this.companyPatentCount.data = [];
      this.companyPatentCount.name = {};
      this.barLoading = true;
      let startTime = new Date().valueOf();
      api
        .getCompanyPatentCount(params)
        .then((res) => {
          console.log("公司专利排行", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.companyPatentCount.legend.push(legend_item);
            this.companyPatentCount.name[legend_item] = i.full_name;
            // this.companyPatentCount.data.push(i.num);
            this.companyPatentCount.data.push({
              value: i.num,
              company_guogao_id: i.company_guogao_id,
            });
          });
          this.barLoading = false;
          console.log("companyPatentCount", this.companyPatentCount);
          let endTime = new Date().valueOf();
          console.log(
            `%c专利排行：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.barLoading = false;
        });
    },
    // 公司列表查看明细
    checkDetail(row) {
      console.log("明细", row);
      this.showScoreDetail = true;
    },
    // 获取产业专利关键词
    getIndustryPatentKeyword(company_guogao_id) {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
        company_guogao_id: company_guogao_id,
        filter_type: this.patentParams.filter_type,
      };
      api
        .getIndustryPatentKeyword(params)
        .then((res) => {
          console.log("产业专利关键词", res);
          this.wordCloudDataPatent = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取产业专利年度统计
    getIndustryPatentCount() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.patentCountLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentCount(params)
        .then((res) => {
          console.log("年度统计", res);
          this.patentEchartsData = res.data;
          this.patentCountLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c产业专利年度统计：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentCountLoading = false;
        });
    },
    // 获取产业市场简介
    getIndustryIntroduction() {
      let params = {
        industry_id: this.domain_id,
      };
      api.getIndustryIntroduction(params).then((res) => {
        console.log("产业简介", res);
        this.industryDesc = res.data;
      });
    },
    // 加载更多
    showMore() {
      this.patentParams.page += 1;
      this.getIndustryPatent();
    },
    // 获取产业专利
    getIndustryPatentRival() {
      // this.patent_list = []
      api
        .getIndustryPatent(this.patentParams)
        .then(async (res) => {
          console.log("产业专利", res);
          await res.data.forEach(async (r) => {
            // r.has_graph = await api
            //   .getPatentStructure({
            //     patent_id: r.id,
            //   })
            //   .then((res) => {
            //     console.log("获取专利结构", res);
            //     // if (res.data.structure_node.length == 0) {
            //     //   return res.data
            //     // } else {
            //     //   return res.data
            //     // }
            //     return res.data
            //   })
            // console.log("r", r)
            this.patent_listRival.push(r);
          });
          console.log("patent_list", this.patent_listRival);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取产业专利
    getIndustryPatent() {
      // this.patent_list = []
      this.patentLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatent(this.patentParams)
        .then((res) => {
          console.log("产业专利", res);
          res.data.forEach((r) => {
            this.patent_list.push(r);
          });
          this.patentLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c获取产业专利：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
          // this.patent_list = res.data
        })
        .catch((err) => {
          this.patentLoading = false;
        });
    },
    // 找下标，删数组内容
    deleteArrayObject(arr, val) {
      let num = null;
      arr.forEach((a, index) => {
        if (a.code == val) {
          num = index;
        }
      });
      arr.splice(num, 1);
    },
    // 获取招聘月度排行
    getPostMonthRank(company_id) {
      let params = {
        industry: "",
        filter_type: "相关公司岗位",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
      };
      api.getPostMonthRank(params).then((res) => {
        // console.log("月度排行", res)
        this.talentEchartsData = res.data;
      });
    },
    // 获取招聘岗位统计
    getPostCount(company_id) {
      this.postCount.legend = [];
      this.postCount.data = [];
      this.postCount.name = {};
      let params = {
        industry: "",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
        filter_type: "相关公司岗位",
      };
      api
        .getPostCount(params)
        .then((res) => {
          console.log("岗位统计", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.postCount.legend.push(legend_item);
            this.postCount.name[legend_item] = i.post_all;
            this.postCount.data.push({
              value: i.count,
            });
          });
        })
        .catch((err) => { });
    },
    // 打开网页
    goto(web) {
      window.open(web);
    },
    // 选择公司
    selectCompany(row) {
      // console.log("row", row);
      console.log("select c");
      console.log(this.nodes);
      console.log(this.links);
      let server_permission_str = cookie.getCookie("server");
      let server_permission = JSON.parse(server_permission_str);
      // console.log("server_permission", server_permission)
      let flag = 0;
      let vip_perm = JSON.parse(cookie.getCookie("vip_perm"));
      let has_perm = false;
      vip_perm.forEach((v) => {
        if (v.codename == "kjld_cytp") {
          has_perm = v.has_perm;
        }
      });
      console.log("has_perm", has_perm);
      if (has_perm) {
        let params = {
          full_name: row.full_name,
        };
        // this.$refs.companyDetailHori.selectCompany(row);
        this.$router.push("/company_info?row=" + JSON.stringify(params));
        flag = 1;
        this.$nextTick(() => {
          EventBus.$emit("selectCompanyInfo", JSON.stringify(params));
        });
      } else {
        server_permission.forEach((s) => {
          if (s.codename == "kjld_qytp" && s.has_perm == true) {
            console.log("选择公司", row);
            let params = {
              full_name: row.full_name,
            };
            // this.$refs.companyDetailHori.selectCompany(row);
            this.$router.push("/company_info?row=" + JSON.stringify(params));
            flag = 1;
            this.$nextTick(() => {
              EventBus.$emit("selectCompanyInfo", JSON.stringify(params));
            });
          }
        });
      }

      if (flag == 0) {
        this.$message.warning(
          "该账号暂无此产业链权限，请联系管理员开通VIP权限！"
        );
      }
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // 获取产业树
    getIndustryTree(val, isSpecial = false) {
      console.warn("获取产业树val：", val);
      let params = {
        // root: val,
        root_id: val,
        username: cookie.getCookie("account"),
        is_special: isSpecial
      };
      this.treeLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryTree(params)
        .then(async (res) => {
          // 计算差值确定level
          res.data.nodes.forEach(async (n) => {
            n.category_id = n.id;
            // n.id = undefined;
            n.level_independence = "high";
            // 计算第一个节点和1的差值，为了使节点的level整体偏移，因为该关系图默认显示level1和level2的节点
            let lv = (await res.data.nodes[0].level) - 1;
            n.level -= lv;
          });
          this.nodes = await res.data.nodes;
          this.links = await res.data.links;
          this.treeLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c产业树：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
          // if(JSON.parse(sessionStorage.getItem("subscribe_node"))){
          //   let node_name=JSON.parse(sessionStorage.getItem("subscribe_node")).name
          //   this.$nextTick(()=>{
          //     this.$refs.graphCategory.focusOnNode(node_name)
          // sessionStorage.removeItem("subscribe_node")
          //   })


          // }
        })
        .catch((err) => {
          this.treeLoading = false;
        });
    },
    // 切换全球/中国
    changeGlobalOrChina(val, flag) {
      // flag:1代表产业市场地图,2代表产业链地图
      if (flag == 1 || flag == 2) {
        this.mapTabMarket = val;
        if (val == 1) {
          this.downstreamCompanyList = this.downstreamCompanyListChina;
        } else {
          this.downstreamCompanyList = this.downstreamCompanyListGlobal;
        }
      }
      if (flag == 2) {
        this.tab = val;
        //   if (val == 1) {
        //     this.tableData = this.tableDataChina;
        //   } else {
        //     this.tableData = this.tableDataGlobal;
        //   }
      }
      if (flag == 3) {
        this.mapTabTech = val;
        // if (val == 1) {
        //   this.tableData = this.tableDataChina;
        // } else {
        //   this.tableData = this.tableDataGlobal;
        // }
      }
      // this.getCompanyByProduct();
    },
    // 获取专利技术地图分布
    getIndustryPatentScatter() {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
      };
      this.mapLatentTech = [];
      this.patentScatterLoading = true;
      let startTime = new Date().valueOf();
      api
        .getIndustryPatentScatter(params)
        .then((res) => {
          console.log("专利分布", res);
          this.mapLatentTech = res.data;
          this.patentScatterLoading = false;
          let endTime = new Date().valueOf();
          console.log(
            `%c专利分布：%c${(endTime - startTime) / 1000}`,
            "color: blue;font-weight:bold",
            "color: orange;font-weight:bold"
          );
        })
        .catch((err) => {
          this.patentScatterLoading = false;
        });
    },
    // 获取产业前沿机构
    getIndustryFrontierOrganization(node_id) {
      console.log("获取产业前沿机构", node_id);
      let params = {
        node_id: node_id,
      };
      this.mechainismLoading = true;
      api
        .getIndustryFrontierOrganization(params)
        .then((res) => {
          console.log("获取产业前沿机构", res);
          this.mechainismList = res.data;
          this.mechainismLoading = false;
        })
        .catch((err) => {
          this.mechainismLoading = false;
        });
    },
    // 获取产业前沿关键词
    getIndustryFrontierWordClouds(node_id) {
      console.log("获取产业前沿关键词", node_id);
      let params = {
        node_id: node_id,
      };
      let upData = [];
      this.frontierWordLoading = true;
      api
        .getIndustryFrontierWordClouds(params)
        .then((res) => {
          console.log("获取产业前沿关键词", res);
          // this.wordList = res.data
          res.data.forEach((element) => {
            let itemData = [];
            itemData.push(element.name);
            itemData.push(element.count);
            upData.push(itemData);
          });
          this.wordList = upData;
          this.frontierWordLoading = false;
        })
        .catch((err) => {
          this.frontierWordLoading = false;
        });
    },
    // 获取产业前沿新闻
    getIndustryFrontierDetail(node_id) {
      if (this.frontierParams.node_id !== node_id) {
        this.newsList = []
      }
      this.frontierParams.node_id = node_id
      console.log('前沿', this.frontierParams);
      this.frontierNewsLoading = true;
      api
        .getIndustryFrontierDetail(this.frontierParams)
        .then((res) => {
          console.log("获取产业前沿新闻", res);
          this.newsList = [...this.newsList, ...res.data.news_list]
          this.frontierNewsLoading = false;
        })
        .catch((err) => {
          this.newsList = [];
          this.frontierNewsLoading = false;
        });
    },
    // 获取项目投资地区分布
    getIndustryInvestmentRegion(node_id) {
      let params = {
        node_id: node_id,
        page: 1,
        page_size: 10,
      };
      this.regionLoading = true;
      api
        .getIndustryInvestmentRegion(params)
        .then((res) => {
          console.log("获取项目投资地区分布", res);
          this.mapData = res.data;
          this.mapData.forEach((element) => {
            element.region_name_formatt = element.region_name
              .replace("省", "")
              .replace("市", "")
              .replace("自治区", "");
          });
          this.regionLoading = false;
        })
        .catch((err) => {
          this.regionLoading = false;
        });
    },
    listenRegionName() {
      console.log("监听改变地区事件");
      EventBus.$on("changeInvestmentRegionName", (res) => {
        console.log("改变地区res:", res);
        let params = {
          node_id: this.domain_id_select,
          page: 1,
          page_size: 10,
          province: res,
        };
        this.industryInvestmentDetailLoading = true;
        api.getIndustryInvestmentDetail(params).then((re) => {
          console.log("获取项目投资资讯", re);
          this.investmentNewsList = re.data;
          this.industryInvestmentDetailLoading = false;
        });
      });
    },

    // 获取项目投资资讯
    getIndustryInvestmentDetail(node_id) {
      if (this.investParams.node_id !== node_id) {
        this.investmentNewsList = []
      }
      this.investParams.node_id = node_id
      this.industryInvestmentDetailLoading = true;
      api.getIndustryInvestmentDetail(this.investParams).then((res) => {
        console.log("获取项目投资资讯", res);
        this.investmentNewsList = [...this.investmentNewsList, ...res.data]
        // this.investmentNewsList = res.data;
        this.industryInvestmentDetailLoading = false;
      });
    },
    // 获取产业风险标签(分布)
    getIndustryRiskTags(node_id) {
      let params = {
        node_id: node_id,
        page: 1,
        page_size: 10,
      };
      this.riskDistributionLoading = true;
      api.getIndustryRiskTags(params).then((res) => {
        console.log("获取产业风险标签", res);
        this.riskDistributionData = res.data;
        this.riskDistributionLoading = false;
      });
    },
    // 获取产业风险预警
    getIndustryRisk(node_id) {
      if (this.risk_params.node_id !== node_id) {
        this.riskWarningData = []
      }
      this.risk_params.node_id = node_id
      this.riskWarningLoading = true;
      api.getIndustryRisk(this.risk_params).then((res) => {
        console.log("******************************");
        console.log("获取产业风险预警", res);
        if (this.riskWarningData.length < 10) {
          this.riskWarningData = res.data;
        } else {
          this.riskWarningData = [...this.riskWarningData, ...res.data]
        }
        // this.riskWarningData = res.data;
        this.riskWarningLoading = false;
      });
    },
    // 获取产业政策
    getIndustryPolicy(node_id) {
      if (this.policyParams.node_id !== node_id) {
        this.industryPolicyData = []
      }
      this.policyParams.node_id = node_id
      this.industryPolicyLoading = true;
      api.getIndustryPolicy(this.policyParams).then((res) => {
        console.log("获取产业政策", res);
        this.industryPolicyData = [...this.industryPolicyData, ...res.data]
        // this.industryPolicyData = res.data;
        this.industryPolicyLoading = false;
      });
    },
    // 点击产业
    handleClickIndustry(i) {
      console.log("点击产业1111111111111111111111", i);
      console.log(i.name);
      this.isFirst = false;
      // this.getCategoryLink(text);
      this.industrySelected = i.name;
      this.patentParams.industry = i.industry_en;
      console.log("industry_en", i.industry_en);
      this.domain_id = i.domain_id;
      this.patentParams.domain_id = i.domain_id;
      this.tableData = [];
      this.tableDataChina = [];
      this.tableDataGlobal = [];
      this.getIndustryTree(i.domain_id);
      this.getIndustryPosition();
      this.getIndustryIntroduction();
      this.getIndustryDesc();
      this.getCompanyScore(this.tabPosition);
      console.log("产业", i);
      // this.getDownstreamCompany(i.downstream_id);
      if (i.name == "新能源汽车" || i.name == "热泵空调") {
        this.link_industry = ev;
      } else if (i.name == "疫苗") {
        this.link_industry = yiliao;
      } else if (i.name == "呼吸机") {
        this.link_industry = yiliaoqixie;
      }
      // this.getIndustryCompany(i.domain_id);
      // this.getCompanyByCategory(i.text);
    },
    // 监听重置
    listenReset() {
      EventBus.$on("reset", (data) => {
        console.log("监听重置");
        // this.newsType = ""
        // this.newsParams.company = ""
        this.company_id = "";
        this.tabsValue = "1";
        console.log("监听重置");
        this.isFirst = true;
        this.tabRadio = "产业市场";
        this.mapTabMarket = 1;
        this.tab = 1;
        this.patentParams.company_guogao_id = "";
        this.patent_list = [];
      });
    },
    // 监听图谱节点点击
    // listenIndustryGraph() {
    //   EventBus.$on("industry_graph", (data) => {
    //     console.log("监听图谱节点点击");
    //     console.log("industry_graph", data);
    //     // this.getCompanyByCategory(data.industry_node);
    //     if (this.tabRadio == "产业链") {
    //       this.nodeDesc = data.desc;
    //       // this.getIndustryCompany(data.domain_id);
    //       this.domain_id = data.domain_id;
    //       this.getCompanyScore();
    //     }
    //   });
    // },
    handleClickIndustryGraph(data) {
      console.log("点击----", data);
      // this.companyTypePage = 1;
      this.handleCompanyTypePageChange(1) // X.X 点击节点后重置页数，并重新请求数据
      // this.getCompanyByCategory(data.industry_node);
      this.node_name_select = data.industry_node;
      this.domain_id_select = data.domain_id;
      if (this.tabRadio == "产业链") {
        this.nodeDesc = data.desc;
        // this.getIndustryCompany(data.domain_id);
        this.domain_id = data.domain_id;
        // this.getCompanyScore();
        this.handleClickLinkTab(this.current_tab);
      }
    },
    // 监听源产业变化
    listenSourceIndustry() {
      EventBus.$on("choose_source_industry", (data) => {
        console.log("监听源产业变化", data);
        this.patentParams.industry = data.industry_en;
        this.handleClickIndustry(data);
        this.tabRadio = "产业市场";
      });
    },
    // 监听产业人才排行
    listenCompanyPostBar() {
      EventBus.$on("company_post_bar", (data) => {
        this.post_list = [];
        this.post_params.page = 1;
        this.post_params.company_id = data;
        this.post_params.post_classification = "";
        this.getPostInfo();
      });
    },
    // 监听排行bar变化
    listenCompanyPatentBar() {
      EventBus.$on("company_patent_bar", (data) => {
        console.log("监听排行bar变化", data);
        this.patent_list = [];
        this.patentParams.page = 1;
        this.patentParams.company_guogao_id = data;
        // this.patentParams.industry = ""
        this.patentParams.domain_id = "";
        this.getIndustryPatent();
        this.getIndustryPatentKeyword();
      });
    },
    // 监听是否从企业详情的链条节点跳转过来
    listenCompanyDetail() {
      EventBus.$on("company_detail_to_category_graph", (data) => {
        console.log("！！！！监听是否从企业详情的链条节点跳转过来", data);
        this.node_link_list = JSON.parse(data);
        this.tableRadio = "产业链";
        this.$nextTick(() => {
          EventBus.$emit("isIndustryStructure", false);
        });
      });
    },
  },
  activated() {
    if (this.links.length != 0) {
      // 处理在产业舆情选择了下拉后，切换到产业图谱时异步渲染产业链
      let nodes = JSON.parse(JSON.stringify(this.nodes));
      let links = JSON.parse(JSON.stringify(this.links));
      this.nodes = [];
      this.links = [];
      this.$nextTick(() => {
        this.nodes = nodes;
        this.links = links;
      });
    }
    let active_vip = sessionStorage.getItem("active_vip");
    if (active_vip == 1) {
      this.getHasPerm();
      this.getIndustryGraphOptionRadar();
      sessionStorage.setItem("active_vip", 0);
    }
  },

  mounted() {
    // this.$router.push("/category_graph");
    // this.is_industry_graph()
    this.setNodeCompany();
    this.getHasPerm(); //获取产业图谱权限
    this.listenRegionName();
    this.$nextTick(() => {
      EventBus.$emit("isIndustryStructure", true);
    });
    this.is_normal = cookie.getCookie("is_normal");
    this.subscribeNodeName = JSON.parse(
      sessionStorage.getItem("subscribeNodeName")
    );
    console.log("$route", this.$route);
    cookie.setCookie("serverIndex", 3);
    console.log("route.query", this.$route.query);
    if (this.$route.query.from == "company_detail_hori") {
      console.log("node_link_list", this.$route.query);
      this.$nextTick(() => {
        this.node_link_list = JSON.parse(this.$route.query.query);
        console.log("heiheieheeheihiehie");
      });

      this.tabRadio = "首页";
      this.$nextTick(() => {
        EventBus.$emit("isIndustryStructure", false);
      });
    }
    // this.getIndustryPatent()
    console.log(
      "---------------------------------------------------------------"
    );
    console.log("links", ev.links);
    console.log(
      "industry",
      JSON.parse(sessionStorage.getItem("index_industry"))
    );
    // this.getIndustryTree("新能源汽车")
    // this.newsParams.permissions = sessionStorage.getItem("permissions");
    this.getIndustryGraphOption();
    this.getIndustryGraphOptionRadar();
    this.permissions = cookie.getCookie("permissions");
    cookie.setCookie("serverValue", "产业图谱");
    let index_industry = JSON.parse(sessionStorage.getItem("index_industry"));
    // this.listenReset();
    // this.listenIndustryGraph();
    if (this.$route.query.from == "personal_subscribe") {
      let params = JSON.parse(sessionStorage.getItem("subscribe_node"));
      this.$nextTick(() => {
        this.chooseIndustrySubscribe(params);
      });
    } else {

      this.handleClickIndustry(index_industry);
    }
    this.listenSourceIndustry();
    this.listenCompanyPatentBar();
    this.listenCompanyPostBar();
    // this.listenCompanyPostRank();
    this.listenTreeClick();
    this.listenIndexIndustry();
    this.listenIndustryDrop();
    // this.listenDetailNewsProduct()
    // this.listenDetailPostProduct()
    this.listenDetailPatentProduct();
    this.listenCompanyDetail();
    this.listenClickToFirst();
    this.listenUpdateSubscribe();
    // this.getCategoryLink()
  },
  beforeDestroy() {
    // EventBus.$off("reset");
    // EventBus.$off("industry_graph");
    EventBus.$off("industry_drop");
    EventBus.$off("choose_source_industry");
    EventBus.$off("company_post_bar");
    EventBus.$off("company_patent_bar");
    EventBus.$off("company_post_rank");
    EventBus.$off("tree_click");
    EventBus.$off("index_industry");
    EventBus.$off("company_detail_to_category_graph");
    EventBus.$off("clickToFirst");
    EventBus.$off("updateSubscribe");
  },
};
</script>
<style lang="scss">
.companyTyleList {
  .el-radio-button__inner {
    height: 20px !important;
    line-height: 2px !important;
  }
}

.companyTypeTable {
  .el-table {
    th.is-leaf {
      border-right: 1px solid #fff;
    }
  }
}

.labelItem {
  background: #e0f3fd;
  color: #4095e6;
  margin-right: 10px;
  padding: 2px 5px;
}

.CategoryGraph {
  // margin-top: 52px;
  width: 100%;
  min-height: 90vh;
  // width: calc(100vw - 76px);
  // padding-top: 10vh;
  padding-bottom: 10px;
  background-color: white;

  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  .headerTop {

    // display: flex;
    // justify-content: space-between;
    .btn {
      margin-right: 15px;
      float: right;
      margin-top: 15px;
    }
  }

  .productAnalyse-patent {
    .el-icon-close::before {
      color: white;
    }
  }

  .el-form-item {
    margin-bottom: 2px;
  }

  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }

  &-right {
    margin-left: 5px;
  }

  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }

  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }

  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;

    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;

      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }

  .newsEchartsBox {
    background-color: white;
    display: flex;
    justify-content: space-around;
    min-height: 250px;
    width: 100%;
    // margin-top: 20vh;
    border-radius: 6px;

    .btnsubscribeBox {
      display: flex;
      // justify-content: space-between;
      align-items: flex-end;

      .btnsubscribeTooltip {
        min-width: 140px;
        color: #999;
        font-size: 12px;
      }
    }
  }

  .industryBox {
    // width: 60vw;
    flex-shrink: 0;
    width: 60%;
    margin-left: 20%;
    // height: 30vw;
    // margin-left: 9.5vw;
    margin-top: 1vw;
    border: 1px solid silver;
    padding-left: 20px;
    padding-top: 20px;
    border-radius: 10px;
    border-color: rgb(239, 239, 239);

    // border: 1px solid black;
    // flex-wrap: wrap;
    // justify-content: space-between;
    // display: flex;
    // justify-content: center;
    // justify-content: space-around;
    // .not_online {
    //   filter: brightness(30%);
    // }
    &-item {
      // width: 10vw;
      // height: 10vw;
      width: 22.5%;
      // height: 29.9%;
      height: 0;
      position: relative;
      padding-bottom: 22.5%;
      // margin-left: 1vw;
      margin: 0 1% 1vw 1%;
      cursor: pointer;
      float: left;
      border: 1px solid;
      border-color: rgb(189, 220, 242);
      border-radius: 7px;

      // margin-bottom: 1vw;
      // background-color: #333333;
      &-img {
        // width: 100%;
        // height: 100%;
        width: 74%;
        height: 74%;
        margin-left: 13%;
        margin-top: 7%;
        position: absolute;
        // filter: brightness(50%);
      }

      .lockBox {
        width: 74%;
        height: 74%;
        margin-left: 13%;
        margin-top: 7%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        .lockLink {
          display: flex;
          color: #fff;
          width: 100%;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          font-weight: bold;

          &-img {
            width: 12%;
            height: 9%;
            margin-right: 5px;
          }
        }

        .lockImg {
          width: 20%;
        }
      }
    }

    &-text {
      // position: absolute;
      // width: 100%;
      // height: 100%;
      // display: flex;
      // top: 0;
      // justify-content: center;
      // align-items: center;
      // color: white;
      // font-size: 20px;
      // text-align: center;
      position: absolute;
      width: 100%;
      // font-size: 20px;
      font-size: 0.9vw;
      text-align: center;
      color: black;
      display: block;
      bottom: 5px;
      font-weight: bold;
    }
  }

  .selectType {
    position: absolute;
    z-index: 10;
    top: 24vh;
    left: 6.5vw;

    &-img {
      display: inline-block;
      // object-fit: fill;
      width: 50px;
      height: 40px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      div {
        width: 50px;
        position: absolute;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
      }

      .high {
        background-color: rgba($color: #000, $alpha: 0.5);
        color: #389bb7;
      }

      img {
        width: 50px;
        height: 40px;
        object-fit: cover;
      }
    }
  }

  tr:first-of-type {
    background-color: white;
  }

  #companyTable {
    margin-top: 20px;
  }

  .introduce {
    // position: absolute;
    // bottom: 9vh;
    width: 100%;

    // min-height: 0vh;
    // border: 1px solid #e4eef6;
    // z-index: 1000;
    // transition-duration: 1s;
    // background-color: rgb(0, 0, 51);
    // box-shadow: inset 0px 0px 5px 3px rgb(5, 5, 129);
    .close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 10px;
      top: 10px;
    }

    table {
      width: 100%;
      color: #333333;
      margin-left: 0;

      // background-color: rgba($color: #000000, $alpha: 0.4);
      td {
        // border-bottom: solid rgba($color: white, $alpha: 0.3) 1px;
        padding: 10px;
        text-align: center;
      }

      td:nth-child(odd) {
        width: 13%;
        background-color: #f2f9fc;
      }

      td:nth-child(even) {
        width: 20%;
      }
    }

    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;

      p {
        font-size: 14px;
        margin: 5px 0;

        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }

      h1 {
        margin: 5px 0;
        font-size: 18px;

        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }

    .detail {
      width: 100%;

      // max-width: 1200px;
      &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgba(91, 91, 94, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #d3feff;
          font-size: 30px;
          font-weight: bold;
        }
      }

      .detailTop {
        display: flex;
        // background-color: rgba($color: #000000, $alpha: 0.4);
        border-bottom: solid silver 1px;
        // img {
        //   margin: 20px 10px 20px 25px;
        //   object-fit: contain;
        //   height: 80px;
        //   width: 80px;
        //   background: white;
        // }
      }

      .compLogo {
        margin: 20px 10px 20px 25px;
        height: 80px;
        width: 80px;
        // background-color: white;
        // border: 1px solid gray;
        background-color: #01a4d9;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          width: 80px;
          overflow: hidden;
          word-break: break-all;
          word-wrap: wrap;
          margin-left: 7px;
          text-align: center;
          color: white;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 8px;
        }
      }
    }
  }

  .el-dialog__body {
    padding-top: 10px;
  }

  .radioBox {
    width: 100%;
    // position: absolute;
    // z-index: 1000;
    background-color: white;
    margin-top: 4px;
    padding-left: 15px;
    padding-top: 10px;
  }

  .nodeDesc {
    padding: 0 10px 0 10px;
    font-size: 15px;
  }

  table {
    margin-left: 0;
  }

  .market {
    padding: 0 15px 0 15px;
    margin-top: 20px;

    // display: flex;
    // justify-content: space-between;
    &-top {
      width: 98%;

      .industryDesc {
        margin-top: 10px;
        width: 61.5vw;
        text-indent: 35px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }

    &-bottom {
      width: 98%;
      display: flex;
      justify-content: space-between;
    }
  }

  .tech {
    width: 100%;

    &-top {
      display: flex;
    }
  }

  .industryLink_up {
    // width: 30%;
    width: 20vw;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #03a7f0;
    padding-top: 10px;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected {
      background-color: #d9f2fd;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #03a7f0;
    }

    &-headSelected {
      // color: #3E43F4;
      // background-color: #3e43f4;
      border: 2px solid rgba(2, 167, 240, 1);
      // color: white;
      cursor: pointer;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      &-noChild {
        border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .showMoreBox {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
  }

  .tableMore {
    // text-align: center;
    cursor: pointer;
  }

  .industryLink {
    // width: 30%;
    font-size: 16px;
    width: 20vw;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #03a7f0;
    padding-top: 10px;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      // border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected,
    &-headNow {
      background-color: #d9f2fd;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      border-left: 1px solid #02a7f0;
      border-right: 1px solid #02a7f0;
      border-top: 1px solid #02a7f0;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #03a7f0;
    }

    &-headSelected {
      // color: #3E43F4;
      // background-color: #3e43f4;
      border: 2px solid rgba(2, 167, 240, 1);
      // color: white;
      cursor: pointer;

      &:hover {
        background-color: rgba(2, 167, 240, 1);
        color: white;
      }
    }

    &-headNow {
      background-color: #02a7f0;
      color: white;
      cursor: pointer;
      font-weight: bold;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      border-left: 1px solid #02a7f0;
      border-right: 1px solid #02a7f0;
      border-bottom: 1px solid #02a7f0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      flex-wrap: wrap;

      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #bde8fb;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #02a7f0;
      }

      &-headSelected {
        // color: #3E43F4;
        // background-color: #3e43f4;
        border: 2px solid rgba(2, 167, 240, 1);
        // color: white;
        cursor: pointer;

        &:hover {
          background-color: rgba(2, 167, 240, 1);
          color: white;
        }
      }

      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .industryLink_medium {
    // width: 30%;
    width: 20vw;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #03a7f0;
    padding-top: 10px;
    background-color: #e7f7fe;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected {
      background-color: #d9f2fd;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      margin-left: -2;
      display: flex;
      justify-content: center;
      color: #03a7f0;
    }

    &-headSelected {
      // color: #3E43F4;
      // background-color: #3e43f4;
      border: 2px solid rgba(2, 167, 240, 1);
      // color: white;
      cursor: pointer;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #bde8fb;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #03a7f0;
      }

      &-headSelected {
        // color: #3E43F4;
        // background-color: #3e43f4;
        border: 2px solid rgba(2, 167, 240, 1);
        // color: white;
        cursor: pointer;
      }

      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .industryLink_down {
    // width: 30%;
    width: 20vw;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 2px solid #03a7f0;
    padding-top: 10px;

    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }

    &-head,
    &-headSelected {
      background-color: #d9f2fd;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #03a7f0;
    }

    &-headSelected {
      // color: #3E43F4;
      // background-color: #3e43f4;
      border: 2px solid rgba(2, 167, 240, 1);
      // color: white;
      cursor: pointer;
    }

    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;

      &-head {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #58b6e5;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #58b6e5;
      }

      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }

  .doubleArrow {
    width: 90%;
    margin-left: 5%;
  }

  .mores {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;

    &-text {
      cursor: pointer;
      background-color: #f4f4f4;
      padding: 5px 40px 5px 40px;

      &:hover {
        background-color: #d8d7d7;
      }
    }
  }

  .el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto !important;
  }

  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }

  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(205, 205, 221);
    border-radius: 5px;
  }

  .postDetail {
    &-post {
      font-size: 20px;
      font-weight: bold;
      color: black;
      display: flex;
      justify-content: space-between;
      padding-right: 15px;

      span {
        font-size: 18px;
        color: #5c7bd9;
      }
    }

    &-company {
      font-size: 16px;
      margin-top: 5px;
    }

    &-info {
      margin-top: 25px;
    }

    &-line {
      margin: 0 5px 0 5px;
      color: silver;
    }

    &-position_info {
      margin-top: 20px;
    }

    &-url {
      margin-top: 10px;
    }
  }

  .diyTab {
    width: 100%;
    height: 45px;
    background-color: #d6e4f1;
    display: flex;

    &-item,
    &-itemSelected {
      cursor: pointer;
      width: 110px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s;
    }

    &-itemSelected {
      background-color: white;
      border-bottom: 2px rgb(64, 149, 229) solid;
      margin-bottom: -2px;
      font-weight: bold;
      transition: all 0.5s;
    }
  }

  // .nameAndSubscribe {
  //   display: flex;
  //   width: 100%;
  //   margin-top: 10px;
  //   // justify-content: space-between;
  //   align-items: center;
  //   &-name {
  //     font-size: 16px;
  //     color: black;
  //     margin-left: 20px;
  //     margin-right: 10px;
  //     font-weight: bold;
  //   }
  // }
  .circleTab {
    display: flex;
    margin-bottom: 30px;
    margin-top: 10px;

    &-item {
      transition: all 0.3s;
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 1px solid #78bbff;
      border-right: none;
      font-size: 14px;
      height: 35px;
      padding: 3px 20px 3px 20px;

      &:first-of-type {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      &:last-of-type {
        border-right: 1px solid #78bbff;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }

    &-itemSelected {
      transition: all 0.3s;
      background-color: #409eff;
      color: white;
    }
  }

  .rightPart {
    position: relative;
    width: 50%;
    margin-left: 1.2%;
  }

  .tabModule {
    border: 1px solid rgb(64, 158, 255);
    border-radius: 10px;
    padding: 10px;
    margin-right: 10px;
    position: relative;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .subtitleSmall {
    color: black;
    font-size: 16px;

    // margin-bottom: 15px;
    span {
      color: #6c6c6c;
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .mechainismAndWord {
    display: flex;
    position: relative;
  }

  .textSmall {
    font-size: 15px;
    color: rgba(79, 79, 79, 1);
    margin-top: 5px;
    text-indent: 15px;
  }

  .riskDistribution {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .arrow {

    &-left,
    &-right {
      z-index: 2000;
      cursor: pointer;
      position: absolute;
      width: 60px;
      height: 70px;
      margin-left: 13%;
      margin-top: 21%;
    }

    &-right {
      transform: rotate(180deg);
      margin-left: 0;
      right: 13%;
    }
  }

  .industryType {
    // transition-duration: 1s;
  }

  .addBtn-area {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
</style>
