<template>
  <!-- 产业前沿机构组件 -->
  <div class="IndustryFrontierMechanismBody">
    <EmptyTip :show="dataList.length == 0 && loading == false"></EmptyTip>
    <van-steps direction="vertical" active-color="#999" inactive-color="#999">
      <van-step v-for="(item, index) in dataList" :key="index">
        <p class="stepP" @click="handleClickStep(item)">{{ item.name }}</p>
        <template #active-icon>
          <i class="iconColor">•</i>
        </template>
        <template #inactive-icon>
          <i class="iconColor">•</i>
        </template>
      </van-step>
    </van-steps>
  </div>
</template>
<script>
import api from "@/api/api";
import EmptyTip from "@/components/EmptyTip.vue";

export default {
  components: {
    EmptyTip,
  },
  props: {
    dataList: Array,
    loading: Boolean
  },
  data() {
    return {
      activeNames: ["1"],
      controlIcon: true,
    };
  },
  methods: {
    handleClickStep(item) {
      console.log("handleClickStep", item)
      this.$emit("clickCompany", item.id)
    },
    changeIcon() {
      if (this.controlIcon) {
        // console.log('1')
        this.$refs.IndustryLabelIcon.style =
          "transform: rotate(90deg);transition: all 0.3s linear;";
      } else {
        // console.log('2')
        this.$refs.IndustryLabelIcon.style =
          "transform: rotate(0deg); transition: all 0.3s linear;";
      }
      this.controlIcon = !this.controlIcon;
    },
  },
};
</script>
<style lang="scss">
.IndustryFrontierMechanismBody {
  min-height: 500px;
  .van-cell {
    flex-direction: row-reverse;
  }
  .iconImg {
    height: 0.15rem;
    margin-right: 0.1rem;
  }
  .iconColor {
    color: #70b603;
    font-size: 45px;
  }
  .stepP {
    margin-left: 11px;
    font-size: 14px;
    color: #111;
    cursor: pointer;
  }
  .van-collapse-item__content {
    padding-top: 0;
  }
}
</style>