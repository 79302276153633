<template>
  <div class="IndustryPolicy">
    <van-steps direction="vertical" active-color='#999' inactive-color='#999'>
      <van-step v-for="risk in apiData" :key="risk" @click-step="showPolicyDetail(risk)">
        <p>
          <span class="IndustryPolicy-release_time">{{risk.release_time}}</span>
          <span class="IndustryPolicy-source">{{risk.source}}</span>
        </p>
        <p class="IndustryPolicy-title" @click="showPolicyDetail(risk)">{{risk.title}}</p>
        <template #active-icon>
          <i class="iconColor">•</i>
        </template>
        <template #inactive-icon>
          <i class="iconColor">•</i>
        </template>
      </van-step>
    </van-steps>

    <NewsDetailShow
      v-if="policyDetailShow"
      :visible="policyDetailShow"
      :newsDetailData="policyDetail"
      @changeVisible="handlePolicyDetailShow"
    ></NewsDetailShow>
  </div>
</template>
<script>
import NewsDetailShow from "@/components/NewsDetailShow.vue"
export default {
  components:{
    NewsDetailShow
  },
  props: {
    apiData: Array,
  },
  data(){
    return {
      policyDetail:{},
      policyDetailShow:false,
    }
  },
  watch: {
    apiData: {
      handler(newVal, oldVal) {
        console.log("newsNewVal", newVal)
        console.log("newsOldVal", oldVal)
        if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
          this.policyDetailShow = false
        }
        
      },
      deep: true
    }
  },
  methods: {
    handlePolicyDetailShow(val){
      this.policyDetailShow = val
    },
    showPolicyDetail(item){
      console.log('showPolicyDetail',item)
      this.policyDetail = item
      this.policyDetailShow = true
    },
  },
};
</script>
<style lang="scss" scoped>
.IndustryPolicy {
  .iconColor {
    color: #81B337;
    font-size: 45px;
  }
  &-release_time, &-source {
    color: rgb(108, 108, 108);
    font-size: 13px;
  }
  &-source {
    margin-left: 20px;
  }
  &-title {
    margin-top: 5px;
    color: black;
  }
}

</style>