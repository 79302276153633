<template>
  <div class="graphC">
    <div ref="graphC" class="graphC" :style="{ height: '100%' }"></div>
    <div id="customMenu">
      <ul>
        <!--右键弹出菜单-->
        <li onMouseOver="this.style.background = '#999999';" onMouseOut="this.style.background = '#CCCCCC';"
          v-for="(item, index) in customMenuList" :key="index" @click="Fn(item.handleFn)">
          <font>{{ item.name }}</font>
        </li>
      </ul>
    </div>
    <el-dialog class="contextMenu" :visible.sync="showMenu" title="请选择" width="20%" style="z-index: 9999"
      destroy-on-close>
      <el-menu class="el-menu-vertical-demo">
        <el-menu-item index="1" @click="
          editNameParams.new_name = '';
        showEdit = true;
        ">
          <span slot="title">编辑节点名称</span>
        </el-menu-item>
        <el-menu-item index="2" @click="
          addNodeParams.name = '';
        showAdd = true;
        ">
          <span slot="title">添加子节点</span>
        </el-menu-item>
        <el-menu-item index="3" @click="removeNode">
          <span slot="title">删除该节点</span>
        </el-menu-item>
        <el-menu-item index="4" @click="handlerClickAddLink()">
          <span slot="title">添加关联公司</span>
        </el-menu-item>
      </el-menu>
    </el-dialog>
    <el-dialog class="editName" :visible.sync="showEdit" title="修改节点名称" width="20%">
      <el-input v-model="editNameParams.new_name" placeholder="请输入节点名称"></el-input>
      <el-button size="small" type="primary" style="margin-top: 10px" @click="changeNodeName">确定</el-button>
    </el-dialog>
    <el-dialog class="editName" :visible.sync="showAdd" title="添加子节点" width="20%">
      <el-input v-model="addNodeParams.name" placeholder="请输入节点名称"></el-input>
      <el-button size="small" type="primary" style="margin-top: 10px" @click="addCustomNode">确定</el-button>
    </el-dialog>
    <el-dialog title="添加关联公司" :visible.sync="editCustomVisible" width="60%">
      <el-tabs v-model="tabActiveName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="企业搜索" name="companySearch">
          <div v-loading="customCompanyLoading">
            <div style="
          display: flex;
          justify-content: space-between;
          margin: 10px 0 10px 0;
        ">
              <div style="display: flex">
                <el-select v-model="searchParams.search_type" placeholder="请选择" size="small">
                  <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-if="searchParams.search_type == '0'" v-model="searchParams.province" placeholder="请选择地区"
                  size="small" style="margin: 0 0 0 5px">
                  <el-option v-for="item in provinceOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-if="searchParams.search_type == '0'" v-model="searchParams.enterprises_type"
                  placeholder="请选择企业类型" size="small" style="margin: 0 0 0 5px">
                  <el-option v-for="item in enterprisesTypeOptions" :key="item.value" :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <el-input :placeholder="searchPlaceHolder" v-model="searchParams.keyword" class="input-with-select"
                  style="width: 12vw; margin: 0 0 0 5px" size="small" @keyup.enter.native="
                    searchParams.page = 1;
                  searchCompanyListForCustom();
                  ">
                  <el-button slot="append" icon="el-icon-search" type="primary" @click="
                    searchParams.page = 1;
                  searchCompanyListForCustom();
                  "></el-button>
                </el-input>
              </div>
            </div>
            <el-table ref="multipleTable" :data="customCompanyData" tooltip-effect="dark" style="width: 100%" stripe
              @selection-change="handleSelectionChange">
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column prop="short_name" label="公司名">
              </el-table-column>
              <el-table-column prop="loc_province" label="地区">
              </el-table-column>
              <el-table-column prop="enterprises_type" label="企业类型">
              </el-table-column>
              <el-table-column prop="founded_time" label="成立时间">
              </el-table-column>
              <!-- <el-table-column label="名录标签">
          <template slot-scope="scope">
            <span
              class="labelItem"
              v-for="(item, index) in scope.row.allLabel"
              :key="index"
            >
              {{ item }}
            </span>
          </template>
        </el-table-column> -->
            </el-table>
            <div style="
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 15px 0 15px 0;
      ">
              <el-pagination v-if="total > 10" background layout="prev, pager, next"
                :current-page.sync="searchParams.page" :total="total" :page-size="searchParams.page_size" :page-count="5"
                @current-change="handlePageChange">
              </el-pagination>
            </div>
            <div class="addBtn-area">
              <el-button size="small" type="primary" @click="addToCustomLink">关联至节点</el-button>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="导入企业" name="companyImport">
          <div class="import-region">
            <div class="example-region">
              <div>
                <el-button @click="exampleFileDownLoad">下载Excel模板</el-button>
              </div>
              <el-upload class="upload-demo" ref="upload" action="" :format="['xls', 'xlsx']" accept=".xls,.xlsx"
                :auto-upload="false" :file-list="fileList" :on-change="handleFileChange" multiple :show-file-list="false">
                <el-button class="pan-btn yellow-btn ">上传Excel</el-button>
              </el-upload>
            </div>
            <div class="successTable-region">
              <p v-if="fileUploadSuccessList && fileUploadSuccessList.length != 0
                " class="tip">
                以下列出部分数据以便核验
              </p>
              <el-table v-if="fileUploadSuccessList && fileUploadSuccessList.length != 0
                " ref="multipleTable" :data="fileUploadSuccessList" tooltip-effect="dark" stripe>
                <el-table-column prop="full_name" label="公司全称"></el-table-column>
                <el-table-column prop="short_name" label="公司简称"></el-table-column>
                <el-table-column prop="country" label="所属国家"></el-table-column>
              </el-table>
            </div>
          </div>

          <!-- fileUploadSuccessList -->
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus";
import cookie from "@/utils/common.js";
import api from "@/api/api";
export default {
  name: "graphC",
  props: [
    "eheight",
    "nodes",
    "links",
    "longEdge",
    "node_link_list",
    "select_node",
    "is_custom",
  ],
  data() {
    return {
      total: 0,
      searchParams: {
        page: 1,
        page_size: 10,
        keyword: "",
        search_type: "0",
        province: "全国",
        enterprises_type: "全部",
      },
      searchPlaceHolder: "请输入企业名称",
      // 搜索类型数据项
      typeOptions: [
        {
          value: "0",
          label: "按企业搜",
        },
        {
          value: "1",
          label: "按产业搜",
        },
      ],
      // 地区筛选数据项
      provinceOptions: [
        { name: "全国", value: "全国" },
        { name: "北京", value: "北京" },
        { name: "天津", value: "天津" },
        { name: "河北", value: "河北" },
        { name: "山西", value: "山西" },
        { name: "内蒙古", value: "内蒙古" },
        { name: "辽宁", value: "辽宁" },
        { name: "吉林", value: "吉林" },
        { name: "黑龙江", value: "黑龙江" },
        { name: "上海", value: "上海" },
        { name: "江苏", value: "江苏" },
        { name: "浙江", value: "浙江" },
        { name: "安徽", value: "安徽" },
        { name: "福建", value: "福建" },
        { name: "江西", value: "江西" },
        { name: "山东", value: "山东" },
        { name: "河南", value: "河南" },
        { name: "湖北", value: "湖北" },
        { name: "湖南", value: "湖南" },
        { name: "广东", value: "广东" },
        { name: "广西", value: "广西" },
        { name: "海南", value: "海南" },
        { name: "重庆", value: "重庆" },
        { name: "四川", value: "四川" },
        { name: "贵州", value: "贵州" },
        { name: "云南", value: "云南" },
        { name: "西藏", value: "西藏" },
        { name: "陕西", value: "陕西" },
        { name: "甘肃", value: "甘肃" },
        { name: "青海", value: "青海" },
        { name: "宁夏", value: "宁夏" },
        { name: "新疆", value: "新疆" },
        { name: "台湾", value: "台湾" },
        { name: "香港", value: "香港" },
        { name: "澳门", value: "澳门" },
      ],
      // 企业类型筛选数据项
      enterprisesTypeOptions: [],
      customCompanyLoading: false,
      editCustomVisible: false,
      searchValue: "",
      removeNodeParams: {
        account: "",
        node_id: "",
      },
      multipleSelection: [],
      customCompanyData: [],
      addNodeParams: {
        father_id: "",
        account: "",
        level: "",
        name: "",
      },
      editNameParams: {
        node_id: "",
        account: "",
        new_name: "",
      },
      showAdd: false,
      showEdit: false,
      showMenu: false,
      graphC: "",
      node_name_list: [],
      node_link_list_data: [],
      industryChainData: [],
      customMenuList: [
        { id: 1, name: "编辑节点名称", handleFn: "changeNodeNameShow" },
        { id: 2, name: "添加子节点", handleFn: "addCustomNodeShow" },
        { id: 3, name: "删除该节点", handleFn: "removeNode" },
        { id: 4, name: "添加关联公司", handleFn: "handlerClickAddLink" },
      ],
      curNode: {},
      tabActiveName: "companySearch",
      // 导入文件
      fileloading: false,
      fileShow: false,
      fileList: [],
      file: {},
      fileUploadSuccessList: [],
      // 记忆节点展开路径
      memory_link: [],
      temp_memory_link: [],
      // 记忆节点点击
      click_list: [],
    };
  },
  computed: {
    findSource() {
      return this.$store.getters.getFindSource;
    },
  },
  watch: {
    node_link_list: {
      handler(newVal) {
        console.log('1zz', newVal);
      }
    },
    select_node: {
      handler(newVal) {
        console.log('1zz2', newVal);
      }
    },
    "searchParams.search_type": {
      handler(newVal) {
        this.searchParams.keyword = "";
        if (newVal == "0") {
          this.searchPlaceHolder = "请输入企业名称";
        } else {
          this.searchPlaceHolder = "请输入节点名称";
        }
      },
    },
    links: {
      handler(newValue, oldValue) {
        console.warn("当前路径：", this.$route.path);
        console.log("links监听：", newValue, oldValue);
        // TODO 除了同一张图的节点点击，其他形式的节点点击重置记忆列表
        // if (!(oldValue.length == 0 || newValue.length == 0)) {
        //   this.memory_link = [this.nodes[0].name];
        //   if (
        //     !(
        //       this.select_node == this.nodes[0].name ||
        //       [1, 2].includes(this.findNodeDepth(this.select_node))
        //     )
        //   ) {
        //     // 如果选中的节点二级节点往后
        //     this.setMemoryList(this.select_node);
        //   }
        //   this.click_list = [this.select_node];
        // }
        // if (this.$route.path != "/category_graph") {
        //   // 不在产业图谱的url下就不渲染
        //   return;
        // }
        // 如果数据没有变化就不渲染
        if (JSON.stringify(newValue) == JSON.stringify(oldValue)) {
          return;
        }
        // if (this.graphC) {
        //   console.warn("~~~~~~图表已存在~~~~~~");
        //   // 销毁回收
        //   this.graphC.off("click");
        //   echarts.init(this.$refs["graphC"]).dispose();
        // }
        //判断是否产业链节点，展开选中节点
        if (sessionStorage.getItem('is_cy')) {

          let length = JSON.parse(sessionStorage.getItem('index_industry'))?.node_link_list.length - 1
          let expandNodeList = JSON.parse(sessionStorage.getItem('index_industry'))?.node_link_list
          this.drawTree(length, expandNodeList)
          let index=this.findDataIndex(this.industryChainData[0], expandNodeList[0]);
          console.log('zxcv',this.industryChainData);
          console.log('zxcc',expandNodeList[0]);
          console.log('kdex',index);
          this.graphC.dispatchAction({
            type: 'select',
            seriesIndex: 0,  // 树形图的 series 索引，通常为 0
            name: expandNodeList[0]
          });
          if (this.nodes[0].name == expandNodeList[expandNodeList.length - 1]) {
            sessionStorage.removeItem('is_cy')
          }
          // this.graphC.
        } else {
          this.drawTree();
        }
      },
      deep: true,
    },
  },
  mounted() { },
  methods: {
    //查找需要高亮节点的dataindex
    findDataIndex(node, name) {
      let dataIndex = -1;
      let currentIndex = 0;
      if (node.name === name) {
      dataIndex = currentIndex;
      return;
      }
      if (node.children) {
        for (let child of node.children) {
        currentIndex++;
        this.findDataIndex(child, name);
        if (dataIndex !== -1) {
          break;
        }
      }
  }
},
    findCategoryIdByName(data, targetName) {
      let result = null;

      function search(nodes) {
        for (let node of nodes) {
          if (node.name === targetName) {
            result = node.category_id;
            return;
          }
          if (node.children && node.children.length > 0) {
            search(node.children);
            if (result !== null) return;
          }
        }
      }

      search(data);
      return result;
    },
    // focusOnNode(name) {
    //   // const data = this.graphC.getOption().series[0].data
    //   // console.log('fff',this.findCategoryIdByName(data,name))
    //   // 使用 ECharts 的 dispatchAction 方法来聚焦节点
    //   // this.drawTree(2)
    //   let index= this.nodes.findIndex(item=>item.name==="智能汽车类型")
    //   this.nodes[index].collapsed=false
    //   console.log(this.nodes[index]);
    //   this.drawTree(3)
    // },
    findNodeDepth(nodeName) {
      // 获取节点的层级
      let tmpNode = this.nodes.find((item) => {
        return nodeName == item.name;
      });
      return tmpNode
    },
    setMemoryList(nodeName) {
      // 生成记忆列表
      let tmpNode = this.nodes.find((item) => {
        return nodeName == item.name;
      });
      // 找到父级的名称
      let curName = tmpNode.name;
      // 默认根节点展开，不展开当前节点（故-2）
      for (let i = 0; i < tmpNode.level - 2; i++) {
        let curLink = this.links.find((link) => {
          return link.target == curName;
        });
        curName = curLink.source;
        this.memory_link.push(curName);
      }
    },
    handlerClickAddLink() {
      this.searchParams.page = 1;
      this.customCompanyData = [];
      this.searchParams.keyword = "";
      this.total = 0;
      this.searchParams.search_type = "0";
      this.editCustomVisible = true;
      // 获取企业类型列表
      this.getCompanyType();
    },
    getCompanyType() {
      let params = {};
      api.getCompanyType(params).then((res) => {
        this.enterprisesTypeOptions = res.data;
      });
    },
    handlePageChange(val) {
      this.searchParams.page = val;
      this.searchCompanyListForCustom();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    addToCustomLink() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请先勾选公司！");
        return;
      }
      let params = {
        node_id: this.editNameParams.node_id,
        company_id_list: [],
      };
      this.multipleSelection.forEach((m) => {
        params.company_id_list.push(m.id);
      });
      api.customAddCompany(params).then((res) => {
        this.$message.success(res.msg);
      });
    },
    searchCompanyListForCustom() {
      this.customCompanyLoading = true;
      if (this.searchParams.search_type == "1") {
        this.searchParams.province = "全国";
        this.searchParams.enterprises_type = "全部";
      }
      this.customCompanyData = [];
      api
        .searchCustomCompany(this.searchParams)
        .then((res) => {
          res.data.forEach((i) => {
            if (i.short_name) {
              i.name = i.short_name;
            } else {
              i.name = i.full_name;
            }
            //合并名录标签
            i.allLabel = i.directory_label + "；" + i.company_type_tags;
            // console.log('allLabel',i.allLabel)
            i.allLabel = i.allLabel
              .replaceAll("null", "")
              .replaceAll("；", " ");
            //标签去重
            i.allLabel = Array.from(new Set(i.allLabel.split(" ")));
            if (i.allLabel[0] == "") {
              i.allLabel.shift();
            }
            if (i.allLabel[i.allLabel.length - 1] == "") {
              i.allLabel.pop();
            }
            // .toString().replaceAll(',',' ')
            if (i.founded_time != null) {
              i.founded_time = i.founded_time.split("T")[0];
            }
            this.customCompanyData = res.data;
            this.total = res.count;
          });
          this.customCompanyLoading = false;
        })
        .catch((err) => {
          this.customCompanyLoading = false;
        });
    },
    // 删除节点
    removeNode() {
      api.deleteCustomNode(this.removeNodeParams).then((res) => {
        if (res.status == 200) {
          EventBus.$emit("changeNodeName", 1);
          this.showMenu = false;
          this.$message.success(res.msg);
        }
      });
    },
    // 添加子节点
    addCustomNode() {
      api.addCustomNode(this.addNodeParams).then((res) => {
        if (res.status == 200) {
          this.$message.success("添加自定义节点成功！");
          EventBus.$emit("changeNodeName", 1);
          this.showAdd = false;
          this.showMenu = false;
        }
      });
    },
    // 编辑节点名称
    changeNodeName() {
      this.editNameParams.account = cookie.getCookie("account");
      api.changeNodeName(this.editNameParams).then((res) => {
        if (res.status == 200) {
          this.showEdit = false;
          this.showMenu = false;
          this.$message.success("修改节点名称成功！");
          EventBus.$emit("changeNodeName", 1);
        }
      });
    },
    // 生成树图数据
    treeDataGenerate(nodes, links, expandNodeList) {
      console.log('treedata', expandNodeList);
      // 生成图表数据
      let tmpIndustryChainData = [];
      console.log("nodes：", nodes);
      console.log("links：", links);
      const memory_link = this.memory_link;
      const click_list = this.click_list;
      console.log("记忆列表：", memory_link);
      console.log("点击列表：", click_list);
      // TODO 遍历节点
      if (expandNodeList) {
        nodes.forEach((item) => {
          let tmpInfo = {};
          tmpInfo = {
            category_id: item.id,
            fatherId: item.father_id,
            name: item.name,
            desc: item.desc,
            collapsed: !expandNodeList.includes(item.name),
            select: {
              itemStyle: {
                color: "#7EB450",
                borderWidth: 5,
                borderColor: "yellow",
              },
            },
            level: item.level,
            children: [],
          };
          tmpIndustryChainData.push(tmpInfo);
          console.log(tmpIndustryChainData);
        });
      } else {
        nodes.forEach((item) => {
          let tmpInfo = {};
          tmpInfo = {
            category_id: item.id,
            fatherId: item.father_id,
            name: item.name,
            desc: item.desc,
            select: {
              itemStyle: {
                color: "#7EB450",
                borderWidth: 5,
                borderColor: "yellow",
              },
            },
            level: item.level,
            children: [],
          };
          tmpIndustryChainData.push(tmpInfo);
        });
      }

      links.forEach((item) => {
        let fatherName = item.source;
        let childName = item.target;
        let curRootInfo = tmpIndustryChainData.filter((item) => {
          return item.name == fatherName;
        })[0];
        let childInfo = tmpIndustryChainData.filter((item) => {
          return item.name == childName;
        })[0];
        curRootInfo.children.push(childInfo);
      });
      console.log("=================数据生成结束=======================");
      return [tmpIndustryChainData[0]];
    },
    // 编辑节点名称
    changeNodeNameShow() {
      this.editNameParams.new_name = "";
      this.showEdit = true;
    },
    // 添加子节点
    addCustomNodeShow() {
      this.addNodeParams.name = "";
      this.showAdd = true;
    },
    showTreeMenu(oEvent) {
      var menu = document.getElementById("customMenu");
      var pageX = oEvent.pageX;
      var pageY = oEvent.pageY;
      menu.style.left = pageX + 15 + "px";
      menu.style.top = pageY + "px";
      menu.style.display = "block";
      // // 为右键菜单准备信息块的信息;
      // menu.getElementsByClassName("menuValue")[0].innerHTML =
      //   params.data.value + "," + params.data.nodeStatus;
    },
    hideMenu() {
      if (document.getElementById("customMenu")) {
        document.getElementById("customMenu").style.display = "none";
      }
    },
    // 动态绑定方法
    Fn(method) {
      this[method]();
    },
    // 生成树图
    drawTree(activeDepth = 1, expandNodeList) {
      console.log('drawTree', expandNodeList);
      var that = this;
      // 生成树数据
      const copyNodes = JSON.parse(JSON.stringify(that.nodes));
      const copyLinks = JSON.parse(JSON.stringify(that.links));
      let isMemory = this.memory_link && this.memory_link.length > 0;
      this.industryChainData = this.treeDataGenerate(
        copyNodes,
        copyLinks,
        expandNodeList
      );
      console.log("图表数据：", this.industryChainData);
      if (this.graphC) {
        console.warn("~~~~~~图表已存在~~~~~~");
        // 销毁回收
        this.graphC.off("click");
        echarts.init(this.$refs["graphC"]).dispose();
      }
      this.graphC = echarts.init(this.$refs["graphC"]);
      let option = {
        tooltip: {
          show: false,
        },
        series: [
          {
            type: "tree",
            symbol: "circle",
            symbolSize: 25,
            edgeSymbol: ["circle", "arrow"],
            symbolOffset: [10, 0],
            initialTreeDepth: activeDepth,
            zoom: 1, //当前视角的缩放比例
            roam: true, //是否开启平游或缩放
            data: this.industryChainData,
            selectedMode: "single",
            itemStyle: {
              color: "#7EB450",
              borderWidth: 0,
              borderColor: null,
            },
            emphasis: {
              focus: "self",
              itemStyle: {
                color: "#7EB450",
                borderWidth: 5,
                borderColor: "yellow",
              },
            },
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 0.5,
              max: 5,
            },
            label: {
              color: "#000",
              position: "bottom",
              verticalAlign: "middle",
              top: "10%",
              fontWeight: "bold",
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      //去除默认的鼠标右键菜单事件
      document.oncontextmenu = function () {
        return false;
      };
      // 鼠标点击事件挂载
      this.graphC.on("mouseup", (e) => {
        // TODO 有鼠标操作，记录当前节点
        that.curNode = {
          industry_node: e.data.name.replace(/ /g, ""),
          industry_level: e.data.level,
          domain_id: e.data.category_id,
          desc: e.data.desc,
        };
        var oEvent = e.event.event;
        console.log("鼠标点击！！！！", oEvent);
        if (oEvent.button == 0) {
          // TODO 鼠标左击
          const name = e.data.name;
          that.click_list.push(name);
          const curNode = that.graphC._chartsViews[0]._data.tree._nodes.find(
            (item) => {
              return item.name === name;
            }
          );
          console.log("当前节点：", curNode);
          // // 如果当前记忆列表中有当前节点，就删除，否则添加
          // if (that.memory_link.includes(curNode.name)) {
          //   that.memory_link = that.memory_link.filter(function(item) {
          //     return item != curNode.name;
          //   });
          // } else {
          //   that.memory_link.push(curNode.name);
          // }
          // let newData = that.treeDataGenerate(
          //   copyNodes,
          //   copyLinks,
          //   that.memory_link.length > 0
          // );
          // console.log("新数据：", newData);
          // option.series[0].data = newData;
          // option.series[0].initialTreeDepth = -1;
          // that.graphC.setOption(option);
          // TODO 获取右边栏企业信息
          this.$nextTick(() => {
            let graph_data = {
              industry_node: e.data.name.replace(/ /g, ""),
              industry_level: e.data.level,
              domain_id: e.data.category_id,
              desc: e.data.desc,
            };
            that.$emit("clickIndustryGraph", graph_data);
          });
        }
        // 鼠标右键键点击
        if (this.is_custom && oEvent.button == 2) {
          // TODO 弹出操作框
          that.showTreeMenu(oEvent);
          that.editNameParams.node_id = that.addNodeParams.father_id = that.removeNodeParams.node_id =
            e.data.category_id;
          that.addNodeParams.level = e.data.level + 1;
          that.addNodeParams.account = that.removeNodeParams.account = cookie.getCookie(
            "account"
          );
          document.onclick = () => {
            this.hideMenu();
          };
        }
      });
      this.graphC.setOption(option);
      window.addEventListener("resize", function () {
        this.graphC.resize();
      });

    },
    async drawGraph(node_link) {
      console.log("!!!!! drawGraph");
      // let itemStyleNormal = {
      //   normal: {
      //     // color: "#4592FF",
      //     color: "#333333",
      //     borderColor: "#82dffe",
      //     borderWidth: 3,
      //     shadowBlur: 8,
      //     shadowColor: "#82dffe"
      //   }
      // };
      console.log("drawGraph");
      let itemStyleNormal = {
        normal: {
          // color: "#4592FF",
          color: "#7EB450",
          // shadowBlur: 3,
          // shadowColor: "#03fc62",
        },
      };
      let itemStyleNormalSelected = {
        normal: {
          // color: "#4592FF",
          color: "#7EB450",
          borderColor: "yellow",
          borderWidth: 5,
          symbolSize: 60,
          // borderType: "dashed",
          // borderDashOffset: 30
          // shadowBlur: 3,
          // shadowColor: "#03fc62",
        },
      };
      let itemStyleLow = {
        normal: {
          // color: "#4592FF",
          color: "#f06467",
          // borderColor: "#f06467",
          // borderWidth: 3,
          // shadowBlur: 3,
          // shadowColor: "#f06467",
        },
      };
      let itemStyleMedium = {
        normal: {
          // color: "#4592FF",
          color: "orange",
          // borderColor: "#82dffe",
          // borderWidth: 3,
          // shadowBlur: 3,
          // shadowColor: "yellow",
        },
      };
      let h = this.$refs["graphC"].clientHeight;

      let count1 = this.nodes.filter((x) => x.level == 1).length;
      let count2 = this.nodes.filter((x) => x.level == 2).length;
      let count3 = this.nodes.filter((x) => x.level == 3).length;
      let count4 = this.nodes.filter((x) => x.level == 4).length;
      let count5 = this.nodes.filter((x) => x.level == 5).length;
      let count6 = this.nodes.filter((x) => x.level == 6).length;
      let temp = 1;
      // let image = await this.getImgData(
      //   "http://surbot.cn:7713/media/images/ventilo_img.jpg/"
      // );
      // let image = await this.getImgData(
      //   `http://surbot.cn:7713/media/images/${this.nodes[0].image}`
      // );
      // console.log(">>>>>>>>nodes", this.nodes);
      // console.log(">>>>>>>>links", this.links);
      let sourceList = new Set();
      this.links.forEach((i) => {
        sourceList.add(i.source);
      });
      let sourceArr = [...sourceList];
      // console.log("sourceArr", sourceArr);
      // console.log("高亮节点", this.node_name_list)
      // this.setLink(this.node_name_list[0])
      let level2Count = 0;
      console.warn("绘图：", this.nodes);
      for (let index = 0; index < this.nodes.length; index++) {
        // console.log("节点", this.nodes[index])
        this.nodes[index].category = this.nodes[index].level - 1;
        if (this.nodes[index].level_independence == "high") {
          this.nodes[index].itemStyle = itemStyleNormal;
          // if (this.node_name_list.indexOf(this.nodes[index].name) != -1) {
          //   console.log("命中", this.nodes[index]);
          //   this.nodes[index].itemStyle = itemStyleNormalSelected;
          // }
          if (this.nodes[index].name == this.select_node) {
            this.nodes[index].itemStyle = itemStyleNormalSelected;
          }
        } else if (this.nodes[index].level_independence == "medium") {
          this.nodes[index].itemStyle = itemStyleMedium;
        } else {
          this.nodes[index].itemStyle = itemStyleLow;
        }
        if (this.nodes[index].level == 1) {
          //设置第一层节点的位置
          // this.nodes[index].itemStyle.normal.borderColor = "#04f2a7";
          // this.nodes[index].itemStyle.normal.borderWidth = 4;
          this.nodes[index].x = 0;
          this.nodes[index].y = h / 2;

          // this.nodes[index].symbol = "image://" + image; //设置第一个节点的图片
        } else if (this.nodes[index].level == 2) {
          //设置第二层节点的位置
          // this.nodes.forEach((i) => {
          //   if (i.level == 2) {
          //     level2Count++;
          //   }
          // });
          // if (level2Count > 10) {
          // this.nodes[index].x = 1000;
          this.nodes[index].x = 500;
          // } else {
          //   this.nodes[index].x = this.longEdge ? 660 : 300;
          // }

          this.nodes[index].y = level2Count * 200;
          temp++;
          level2Count++;
          // } else if (this.nodes[index].level == 3) {
        } else {
          //设置第三层节点以及三层节点以后层级的位置
          // let level3Count = 0;
          // this.nodes.forEach((i) => {
          //   if (i.level == 3) {
          //     level3Count++;
          //   }
          // });
          // if (level3Count > 10) {
          //   this.nodes[index].x = 2000;
          // } else {
          // this.nodes[index].x = this.longEdge
          //   ? 660 * (this.nodes[index].level - 1)
          //   : 300;
          // }
          // this.nodes[index].x = 2000
          this.nodes[index].x = (this.nodes[index].level - 1) * 500;
          this.nodes[index].y = index * 200;
          temp++;
        }
      }
      for (let index = 0; index < this.links.length; index++) {
        this.links[index].value = this.links[index].type;
      }
      this.graphC = echarts.init(this.$refs["graphC"]);
      this.graphC.getDom().oncontextmenu = function () {
        return false;
      };
      if (this.is_custom) {
        // 只有自定义产业有右键功能
        this.graphC.on("contextmenu", function (params) {
          console.log("图谱右键", params);
          that.editNameParams.node_id = that.addNodeParams.father_id = that.removeNodeParams.node_id =
            params.data.category_id;
          that.addNodeParams.level = params.data.level + 1;
          that.addNodeParams.account = that.removeNodeParams.account = cookie.getCookie(
            "account"
          );
          that.showMenu = true;
        });
      }

      this.graphC.on("click", function (params) {
        //点击判断是否展开和收缩
        if (params.dataType != "node") {
          return;
        }
        console.log("params", params);
        // if (params.dataIndex != 0) {
        let graph_data = {
          industry_node: params.data.name.replace(/ /g, ""),
          industry_level: params.data.level,
          domain_id: params.data.category_id,
          desc: params.data.desc,
        };
        that.$emit("clickIndustryGraph", graph_data);
        // }

        if (params.data.level !== 2) {
          that.$emit("list", params.name);
          that.$emit("node", params.data);
        }

        if (params.data.level == 1) {
          option.series[0].data.forEach((i) => {
            // i.itemStyle = itemStyleNormal;
            if (i.level_independence == "high") {
              if (i.name == params.data.name) {
                i.itemStyle = itemStyleNormalSelected;
              } else {
                i.itemStyle = itemStyleNormal;
              }
            } else if (i.level_independence == "medium") {
              i.itemStyle = itemStyleMedium;
            } else {
              i.itemStyle = itemStyleLow;
            }
          });
          setTimeout(() => {
            that.graphC.setOption(option, true);
            // console.log("option", option);
          }, 500);
          return;
        }
        let length = that.nodes.filter(
          (x) => parseInt(params.data.level) + 1 == x.level
        );

        let array = that.links.filter((x) => params.name == x.source);
        if (!params.data.type) {
          //根据关系过滤出想要的节点
          let option = that.graphC.getOption();
          let temp = 1;
          let arr = [];
          for (let index = 0; index < array.length; index++) {
            let node = that.nodes.filter((x) => x.name == array[index].target);

            if (node.length > 0) {
              temp++;
              arr.push(node[0]);
            }
          }
          arr = Array.from(new Set(arr));
          console.log("arr", arr);

          for (let index = 0; index < arr.length; index++) {
            //设置过滤出来节点的位置
            if (arr.length > 10) {
              arr[index].x = params.data.x + 1200;
            } else {
              arr[index].x = that.longEdge
                ? params.data.x + 600
                : params.data.x + 300;
            }
            arr[index].y = index * 200;
          }

          if (params.data.level == 1) {
            //展开和收缩的主要判断
            // option.series[0].itemStyle.color = "white";
            // console.log(option.series[0]);
            option.series[0].data = that.nodes.filter((x) => x.level < 3);
          } else if (arr.length == 0) {
            option.series[0].data = option.series[0].data.filter(
              (x) => x.level <= params.data.level
            );
          } else if (length.length == 0) {
            option.series[0].data = option.series[0].data.concat(arr);
          } else if (
            option.series[0].data
              .filter((x) => x.level <= params.data.level)
              .concat(arr).length == option.series[0].data.length
          ) {
            option.series[0].data = option.series[0].data.filter(
              (x) => x.level <= params.data.level
            );
          } else if (
            option.series[0].data
              .filter((x) => x.level <= params.data.level)
              .concat(arr).length !== option.series[0].data.length
          ) {
            option.series[0].data = option.series[0].data
              .filter((x) => x.level <= params.data.level)
              .concat(arr);
          }
          // if (params.data.level == "2") {
          //   option.series[0].itemStyle.color = "white";
          //   console.log(option.series[0]);
          // }
          console.log("arrData", option.series[0].data);
          option.series[0].data.forEach((i) => {
            // i.itemStyle = itemStyleNormal;
            if (i.level_independence == "high") {
              if (i.name == params.data.name) {
                i.itemStyle = itemStyleNormalSelected;
              } else {
                i.itemStyle = itemStyleNormal;
              }
            } else if (i.level_independence == "medium") {
              i.itemStyle = itemStyleMedium;
            } else {
              i.itemStyle = itemStyleLow;
            }
          });

          setTimeout(() => {
            that.graphC.setOption(option, true);
            // console.log("option", option);
          }, 500);
        }
      });
      let option = {
        // tooltip: {
        //   // formatter: "呼吸机的知识图谱"
        //   formatter: function (params) {
        //     let level = "";
        //     if (params.data.level_independence == "low") {
        //       level = "低";
        //     } else if (params.data.level_independence == "medium") {
        //       level = "中";
        //     } else if (params.data.level_independence == "high") {
        //       level = "高";
        //     }
        //     if (level) {
        //       return "行业健康度：" + level;
        //     } else {
        //       return "";
        //     }
        //   },
        // },

        label: {
          // position: "bottom",
          x: "left",
          color: "#333333",
          fontWeight: "bold",
          normal: {
            show: true,
            textStyle: {
              fontSize: 12,
            },
          },
        },

        series: [
          {
            type: "graph",
            left: "center",
            top: "55",
            // focusNodeAdjacency: true,
            roam: true,
            // roam: false,
            // nodeScaleRatio: 0.6,
            right: "auto", //组件离容器右侧的距离,百分比字符串或整型数字
            bottom: "auto", //组件离容器下侧的距离,百分比字符串或整型数字
            width: "auto", //图例宽度
            height: "auto",
            layout: "none",
            symbolSize: 35,

            itemStyle: {
              normal: {
                // shadowBlur: 10,
                // shadowColor: "white",
                // backgroundColor: "transparent"
              },
            },
            // emphasis: {
            //   itemStyle: {
            //     color: "#333333",
            //     borderColor: "#b457ff",
            //     borderWidth: 3,
            //     shadowBlur: 8,
            //     shadowColor: "#b457ff"
            //   }
            // },
            categories: [
              {
                symbolSize: 60,
                itemStyle: {
                  normal: {
                    // color: "#009800",
                    // shadowBlur: 15,
                    // shadowColor: "orange"
                    color: "#333333",
                    // borderColor: "#82dffe",
                    // borderWidth: 3,
                    // shadowBlur: 8,
                    // shadowColor: "#04f2a7"
                    // color: function(params) {
                    //   console.log("xxxxx", params);
                    //   if (params.dataIndex % 2 === 0) {
                    //     return "#333333";
                    //   } else {
                    //     return "#82dffe";
                    //   }
                    // },
                    // borderColor: "#82dffe",
                    // borderWidth: 3,
                    // shadowBlur: 8,
                    // shadowColor: "#04f2a7"
                  },
                },
              },
              // {
              //   itemStyle: {
              //     normal: {
              //       // color: "#4592FF",
              //       // color: "#333333",
              //       // color: "#333333",
              //       color: "#333333",
              //       // borderColor: "#82dffe",
              //       // borderWidth: 3,
              //       // shadowBlur: 8,
              //       // shadowColor: "#04f2a7"
              //     },
              //   },
              // },
            ],
            label: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 14,
                },
                //         formatter: [
                // '{b}',
                // '{数量：@num}'
                //     ].join('\n'),
                formatter: function (params) {
                  if (params.data.num) {
                    return params.data.name + " \t" + params.data.num;
                  } else {
                    return params.data.name;
                  }
                },
              },
            },

            edgeSymbolSize: [4, 10],
            edgeSymbol: ["circle", "arrow"],

            edgeLabel: {
              silent: true,
              // normal: {
              //   show: false,

              //   rich: {
              //     c: {
              //       height: 20,
              //       width: 40,
              //       backgroundColor: "gray",
              //       lineHeight: 0,
              //       borderRadius: 8,
              //       color: "#fff",
              //       align: "center",
              //       verticalAlign: "middle",
              //     },
              //   },

              //   textStyle: {
              //     fontSize: 14,
              //     color: "#000",
              //   },
              //   formatter: "{c|{c}}",
              // },
            },

            data: node_link ? node_link : this.nodes.filter((x) => x.level < 3),
            links: this.links,
            // lineStyle: {
            //   silent: true,
            //   normal: {
            //     opacity: 1,
            //     width: 1,
            //     curveness: 0.1,
            //   },
            //   emphasis: {
            //     color: "orange",
            //   },
            // },
          },
        ],
      };
      let that = this;
      this.graphC.setOption(option);
      this.graphC.resize();
      window.addEventListener("resize", function () {
        this.graphC.resize();
      });
    },
    // TODO 企业批量导入样例文件下载
    exampleFileDownLoad() {
      window.open(
        "https://suwen-data.obs.cn-south-1.myhuaweicloud.com/frontend_industryradar/%E4%BC%81%E4%B8%9A%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5%E6%A0%B7%E4%BE%8B%E6%96%87%E4%BB%B6.xls",
        "_self"
      );
    },
    // TODO 上传返回文件内容数据
    handleFileChange(file, fileList) {
      this.fileloading = true;
      this.fileShow = true;
      this.fileList = [fileList[fileList.length - 1]]; // 只能上传一个Excel，重复上传会覆盖之前的
      this.file = file.raw;
      let reader = new FileReader();
      let _this = this;
      reader.readAsArrayBuffer(this.file);
      reader.onload = function () {
        let buffer = reader.result;
        let bytes = new Uint8Array(buffer);
        let length = bytes.byteLength;
        let binary = "";
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        let XLSX = require("xlsx");
        let wb = XLSX.read(binary, {
          type: "binary",
        });
        let outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        console.log("这是上传表格", outdata);
        _this.uploadFileData(outdata);
      };
    },
    // TODO 文件内容数据传到后端
    uploadFileData(dataList) {
      let params = {
        file_data: dataList,
        node_id: this.editNameParams.node_id,
        account: cookie.getCookie("account"),
      };
      api
        .customAddFileCompany(params)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.fileUploadSuccessList = res.data.exist_list;
            let msg = res.data.verify_num
              ? `<p>已成功关联<strong>${res.data.success_num}</strong>家企业</p><p>余下<strong>${res.data.verify_num}</strong>家企业需等待审核通过后自动关联！</p>`
              : `<p>已成功关联<strong>${res.data.success_num}</strong>家企业</p>`;
            this.$notify({
              title: "成功",
              message: msg,
              dangerouslyUseHTMLString: true,
              type: "success",
              duration: 10000,
            });
          } else {
            this.$notify({
              title: "失败",
              message:
                "<p>关联失败，请重试！</p><p style='color:red'>注意：全称不可为空</p>",
              type: "error",
              dangerouslyUseHTMLString: true,
              duration: 10000,
            });
            this.fileUploadSuccessList = [];
          }
          this.$emit("clickIndustryGraph", this.curNode);
        })
        .catch((err) => {
          this.$notify({
            title: "失败",
            message: "<p>关联失败，请重试！</p>",
            type: "error",
            dangerouslyUseHTMLString: true,
            duration: 10000,
          });
          this.fileUploadSuccessList = [];
        });
    },
  },
};
</script>

<style scoped lang="scss">
.graphC {
  width: 100%;
  height: 100%;
}

.right-menu {
  /*这个样式不写，右键弹框会一直显示在画布的左下角*/
  position: absolute;
  background: rgba(3, 3, 3, 0.6);
  border-radius: 5px;
  left: -99999px;
  top: -999999px;
}

.right-menu ul {
  list-style: none;
}

.right-menu ul li {
  padding: 5px 10px;
  color: #ffff;
  border-bottom: 1px dashed #ffffff;
  font-size: 14px;
}

.right-menu ul li:last-child {
  border-bottom: none;
}

#customMenu {
  position: absolute;
  border: 1px outset #f6f6f6 !important;
  border-color: #ffffff;
  z-index: 10000;
  left: 300px;
  border-radius: 10px;
  top: 200px;
  display: none;
  width: 110px;
  background: #cccccc;
  /* filter: alpha(opacity: 80); */
  overflow: hidden;
}

#customMenu ul {
  padding: 0;
  margin: 0px;
  list-style-type: none;
  vertical-align: middle;
}

#customMenu ul li {
  padding: 0px;
  border-color: #ffffff;
  font-family: "微软雅黑";
  padding: 4px;
  width: 100%;
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
}

#customMenu ul li:hover {
  background: "#999999";
  cursor: pointer;
}

#customMenu font {
  padding-left: 4px;
  vertical-align: top;
}

.add-data {
  margin-left: 5px;

  .el-icon-document-add {
    font-size: 20px;
  }
}

/deep/ .el-tabs__header {
  padding: 0 !important;
  padding-left: 0 !important;
}

.import-region {
  display: flex;

  .example-region {
    flex: 3;
    display: flex;

    .upload-demo {
      margin-left: 10px;
    }
  }

  .successTable-region {
    flex: 7;

    .tip {
      color: #cccccc;
      margin-top: 2px;
    }
  }
}
</style>
